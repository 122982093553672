import React, { useState, useEffect } from 'react';
import Sticky from 'react-stickynode';

import DynamicIcon from '@/elements/DynamicIcon.js';

import globalConfig from '@/config/globalConfig.js'
import { getValueFromParameterMap } from '@/cms/NBossCMS.js';
import { motion } from 'framer-motion';

export default function AdministrationLister(props) {
    const [data, setData] = useState(null);

    const [selectedMainCategory, setSelectedMainCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');

    // const [searchTerm, setSearchTerm] = useState(null);

    useEffect(() => {
        if (props.data)
            setData(organizeData(props.data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    const organizeData = (propsData) => {
        let _data = [];

        for (let i = 0; i < propsData.value.contentList.length; i++) {
            let item = propsData.value.contentList[i];

            let mainCategoryStr = item.parameterMap['ugyintezes-kategoria'].value;
            let mainCategoryObj = _getByKey(_data, mainCategoryStr);

            if (!mainCategoryObj) {
                let obj = { key: mainCategoryStr, itemList: [] };
                _data.push(obj);
                mainCategoryObj = obj;
            }

            let subCategoryStr = item.parameterMap['ugyintezes-alkategoria'].value;
            let subCategoryObj = _getByKey(mainCategoryObj['itemList'], subCategoryStr);

            if (!subCategoryObj) {
                let obj = { key: subCategoryStr, itemList: [] };
                mainCategoryObj['itemList'].push(obj);
                subCategoryObj = obj;
            }

            subCategoryObj['itemList'].push(item);
        }

        return _data;
    }

    const _getByKey = (arr, key) => {
        for (let i = 0; i < arr.length; i++)
            if (arr[i]['key'] === key)
                return arr[i];
        return null;
    }



    const getFilteredSubCategoryArr = () => {
        let arr = [];
        for (let i = 0; i < data.length; i++)
            for (let j = 0; j < data[i].itemList.length; j++)
                if ((selectedMainCategory !== '' && selectedMainCategory === data[i].key) || selectedMainCategory === '')
                    arr.push(data[i].itemList[j].key)
        return arr;
    }

    useEffect(() => {
        setSelectedSubCategory('');
    }, [selectedMainCategory])

    const selectClassName = 'w-full px-2 py-2 rounded font-semibold';

    return (
        <div className={'w-full flex flex-col ' + (props.className || '')}>

            <Sticky top={globalConfig.etc.headerStickyOffset}>

                <div className='w-full bg-tv-red p-4 md:p-8 flex flex-col md:flex-row items-start md:items-center gap-4 bg-no-repeat bg-bottom bg-cover content' style={{ backgroundImage: 'url(/assets/images/pattern-red.png)' }} >

                    <div className='flex-1 flex flex-col gap-2 text-white'>
                        <h1>Ügyintézés</h1>
                        <div className='text-sm'> A szűrők segítségével szűkítheti az ügyintézési listát a keresett kategória szerint</div>
                    </div>

                    <div className='w-full flex-1 flex flex-col gap-4'>

                        <select className={selectClassName} onChange={e => { setSelectedMainCategory(e.target.value) }} value={selectedMainCategory}>
                            <option value='' selected>Minden kategória</option>
                            {data && data.map((item) => (<option key={item.key} value={item.key}>{item.key}</option>))}
                        </select>

                        <select className={selectClassName} onChange={e => { setSelectedSubCategory(e.target.value) }} value={selectedSubCategory} disabled={selectedMainCategory === ''}>

                            <option value='' selected>{selectedMainCategory !== '' ? 'Minden alkategória' : 'Válasszon fő kategóriát'}</option>
                            {(data && selectedMainCategory !== '') && getFilteredSubCategoryArr().map((item) => (<option key={item} value={item}>{item}</option>))}

                        </select>

                    </div>

                </div>

            </Sticky>

            {data && <div className='w-full bg-fill-base z-10 flex flex-col p-4 md:p-8'>
                {data.map((item) => (
                    <AdministrationMainCategory
                        key={item.key}
                        data={item}
                        selectedMainCategory={selectedMainCategory}
                        selectedSubCategory={selectedSubCategory} />
                ))}
            </div>}

        </div>
    )
}

function AdministrationMainCategory(props) {
    const visible = (props.selectedMainCategory === '' || props.selectedMainCategory === props.data.key);
    const margin = visible && props.selectedMainCategory === '';

    return (
        <motion.div
            className={'w-full shadow-lg bg-tv-gray-lighter dark:bg-tv-yellow overflow-hidden ' + (margin && 'mb-4 only:mb-0')}
            initial={'visible'}
            animate={visible ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1, height: 'auto' }, 'hidden': { opacity: 0, height: 0 } }}>

            <div className='w-full flex flex-col p-4 md:p-8'>

                <div className='w-full content mb-4 md:mb-6'><h1 className='dark:text-black'>{props.data.key}</h1></div>

                {props.data && <div className='w-full flex flex-col'>
                    {props.data.itemList.map((item) => (
                        <AdministrationSubCategory
                            key={item.key}
                            data={item}
                            selectedMainCategory={props.selectedMainCategory}
                            selectedSubCategory={props.selectedSubCategory} />
                    ))}
                </div>}

            </div>

        </motion.div>
    );
}

function AdministrationSubCategory(props) {
    const visible = (props.selectedSubCategory === '' || props.selectedSubCategory === props.data.key);
    const margin = visible && props.selectedSubCategory === '';

    return (
        <motion.div
            className={'w-full shadow-lg bg-white dark:bg-tv-gray-darkest overflow-hidden ' + (margin && 'mb-4 only:mb-0')}
            initial={'visible'}
            animate={visible ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1, height: 'auto' }, 'hidden': { opacity: 0, height: 0 } }}>

            <div className='w-full flex flex-col p-4 md:p-6'>

                {props.data.key && <div className='content font-bold uppercase tracking-wider mb-4'><h2>{props.data.key}</h2></div>}

                {props.data && <div className='w-full flex flex-col divide-y'>{props.data.itemList.map((item) => (<AdministrationItem key={item.key} data={item} />))}</div>}

            </div>

        </motion.div >
    );
}

function AdministrationItem(props) {

    let document = null;

    if (getValueFromParameterMap(props.data, 'ugyintezes-dokumentum'))
        document = getValueFromParameterMap(props.data, 'ugyintezes-dokumentum').downloadUrl
    else if (getValueFromParameterMap(props.data, 'ugyintezes-dokumentum-url'))
        document = getValueFromParameterMap(props.data, 'ugyintezes-dokumentum-url')

    let _data = {
        title: getValueFromParameterMap(props.data, 'ugyintezes-cim'),
        description: getValueFromParameterMap(props.data, 'ugyintezes-leiras')
    }

    return (
        <div className='w-full py-4 sm:py-1.5 first:pt-0 last:pb-0 flex flex-col sm:flex-row gap-2 sm:gap-4 sm:items-center'>

            {/* {getValueFromParameterMap(props.data, 'ugyintezes-dokumentum') && console.log(getValueFromParameterMap(props.data, 'ugyintezes-dokumentum'))} */}

            <div className='flex-1'>
                <div className='text-sm dark:text-base dark:text-white'>{_data.title}</div>
                {props.data.parameterMap['ugyintezes-leiras'] && <div className='text-xs'>{_data.description}</div>}
            </div>

            {document && <a href={document} target='_blank' rel='noreferrer' className='bg-tv-red hover:bg-tv-purple text-white flex flex-row items-center gap-2 px-2 py-2 sm:py-1 self-stretch transition-colors'>

                <div className='text-xs dark:text-sm font-semibold'>Dokumentum</div>
                <DynamicIcon className='text-sm' iconName={'FaRegFileAlt'} />

            </a>}

        </div>
    );
}