const globalConfig = {
    'etc': {
        'headerStickyOffset': 24,
        'facebookAppId': 987236468580242,
        'ogDefaultImage': 'https://terezvaros.hu/assets/images/og-img.png',
        'cookiePolicyPath': '/oldal/adatkezelesi-tajekoztato',
    },
    'search': {
        'path': 'kereses',
        'queryParam': 'kifejezes'
    },
    'social': [
        { 'key': 'facebook', 'label': 'Facebook', 'url': 'https://www.facebook.com/terezvaros.hu', 'iconName': 'FaFacebookF', 'color': '#1D4ED8' },
        { 'key': 'youtube', 'label': 'YouTube', 'url': 'https://www.youtube.com/channel/UCT7rOR20pRxsahFoehRc_dw', 'iconName': 'FaYoutube', 'color': '#DC2626' }
    ],
    'contact': {
        'address': { 'label': '1067 Budapest, Eötvös u. 3.', 'prefix': 'Cím', 'url': 'https://www.google.com/maps/place/Budapest,+E%C3%B6tv%C3%B6s+u.+3,+1067/data=!4m2!3m1!1s0x4741dc6facba00c7:0x32cb792435a78b38?sa=X&ved=2ahUKEwjCqPX49OnyAhVKJhoKHdKIBW0Q8gF6BAgYEAE' },
        'postal': { 'label': '1395 Budapest 62., Pf. 409.', 'prefix': 'Levelezési cím' },
        'phone': { 'label': '+36 1 342 0905', 'prefix': 'Telefon', 'url': 'tel:+3613420905' },
        'email': { 'label': 'onkormanyzat@terezvaros.hu', 'prefix': 'E-mail cím', 'url': 'maito:onkormanyzat@terezvaros.hu' }
    }
}

export default globalConfig;