import nBossConnection from '@/services/nBossConnection.js';

class CmsFrontendControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'cmsFrontendControl';
    }

    /**
     * @param {domainName} domainName
     * @param {url} url
     */
    getWebsite(domainName, url) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getWebsite`, { domainName, url }).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {key} key
    */
    getPage(key) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getPage`, { key }).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {id} id
    */
    getMenu(id) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getMenu`, { id }).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {key} key
    * @param {first} first
    * @param {limit} limit
    */
    getList(key, first, limit) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getList`, { key, first, limit }).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {key} key
    */
    setLocale(key) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/setLocale`, { key }).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
   * @param {query} query
   */
    searchPageList(query) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/searchPageList`, { query }).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new CmsFrontendControl();
