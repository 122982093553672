import nBossConnection from '@/services/nBossConnection.js';

class VisitorUserRegistrationControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'visitorUserRegistrationControl';
    }

    /**
     * @param {dto} dto
     */
    save(dto) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/save`, { dto }).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {email} email
    * @param {password} password
    */
    login(email, password) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/login`, { email, password }).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new VisitorUserRegistrationControl();
