import React, { useState, useEffect } from 'react';
import { EventRegister } from '@/utils/EventRegister.js';

import StickyHeader from '@/elements/StickyHeader';
import ModalDialog from '@/elements/modal/ModalDialog';
import DynamicIcon from '@/elements/DynamicIcon.js';

import CmsVoteControl from '@/services/CmsVoteControl.js';
import CmsFrontendControl from '@/services/CmsFrontendControl.js';

import { getValueFromParameterMap } from '@/cms/NBossCMS.js';
import { getUser } from '@/utils/UserUtils.js';

import { shuffleArray } from '@/utils/GenericUtils';

export default function Vote(props) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [vote, setVote] = useState(false);
    const [votedKey, setVotedKey] = useState(null);
    const [list, setList] = useState(null);

    useEffect(() => {
        setLoggedIn(getUser() ? true : false)
        let userChangedEvent = EventRegister.addEventListener('userChanged', (data) => { setLoggedIn(data ? true : false); });
        return () => EventRegister.removeEventListener(userChangedEvent)
    }, []);

    useEffect(() => {
        loadList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);

    useEffect(() => {
        if (!loggedIn)
            setVote(null);
    }, [loggedIn]);

    useEffect(() => {
        let _votedKey = null;
        if (list)
            for (let i = 0; i < list.length; i++)
                if (list[i].dataMap.vote.voteValue === 1)
                    _votedKey = list[i].key;
        setVotedKey(_votedKey);
    }, [list]);

    const doVote = () => {
        if (vote && !votedKey) {
            console.log('Vote for: ' + vote.key);
            CmsVoteControl.vote(vote.dataMap.vote.typeKey, vote.key, vote.dataMap.vote.value === 1 ? 0 : 1).then(result => { loadList(); setVote(null); });
        }
    }

    const loadList = () => {
        CmsFrontendControl.getList(props.data.parameterMap.list.value.key, 0, 0).then(result => { setList(shuffleArray(result.contentList)); });
    }

    let _data = {
        heading: getValueFromParameterMap(props.data, 'heading'),
        subHeading: getValueFromParameterMap(props.data, 'sub-heading'),
        introductionContent: getValueFromParameterMap(props.data, 'introduction-content'),
        // list: getValueFromParameterMap(props.data, 'list') && shuffleArray(getValueFromParameterMap(props.data, 'list').contentList),
    }

    return (
        <div className={'w-full flex flex-col ' + (props.className || '')}>

            <StickyHeader heading={_data.heading} subHeading={_data.subHeading} content={_data.introductionContent} />

            <div className='flex-1 flex flex-col gap-4 sm:gap-8 p-4 sm:p-8 bg-fill-base z-10'>

                {list && <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    {list.map((item, index) => <VoteItem key={item.key} data={item} loggedIn={loggedIn} available={!votedKey} voted={votedKey === item.key} onVote={() => loggedIn ? setVote(item) : EventRegister.emit('userAuthNeeded', null)} />)}
                </div>}

            </div>

            {vote && <ModalDialog
                title={'Szavazat leadása'}
                content={'Figyelem! Egy felhasználó egy szavazatot adhat le a Zöld Terézváros egy jelöltjére. Biztosan leadja a szavazatot?'}
                primaryButtonLabel={'Szavazat leadása'}
                onPrimaryButtonClick={() => doVote()}
                secondaryButtonLabel={'Vissza'}
                onSecondaryButtonClick={() => setVote(null)}
                onClose={() => { setVote(null) }} />}

        </div>
    );
}

function VoteItem(props) {
    let _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        position: getValueFromParameterMap(props.data, 'position'),
        description: getValueFromParameterMap(props.data, 'description'),
        image: getValueFromParameterMap(props.data, 'image').downloadUrl,
    }

    const getButtonConfig = () => {
        if (props.loggedIn) {
            if (props.available) {
                return { 'label': 'Szavazat leadása', 'disabled': false };
            } else {
                if (props.voted) {
                    return { 'label': 'Az Ön által megszavazott jelölt', 'disabled': true };
                } else {
                    return { 'label': 'Szavazatát már leadta', 'disabled': true };
                }
            }
        } else {
            return { 'label': 'A szavazat leadásához bejelentkezés szükséges', 'disabled': false };
        }
    }

    return (
        <div className='flex flex-col shadow-xl dark:bg-tv-yellow'>

            <div className={'relative w-full flex items-end overflow-hidden ' + (_data.image ? 'bg-tv-gray-light dark:bg-tv-gray-darkest' : 'bg-tv-red-dark')} >
                {_data.image && <svg className='w-full invisible' viewBox='0 0 16 9' />}

                <div className='absolute w-full h-full bg-cover bg-center filter dark:opacity-50' style={{ backgroundImage: 'url(' + _data.image + ')' }} />
                <div className={'m-4 font-bold text-white text-semibold flex flex-col items-start gap-1 ' + (_data.image && 'absolute bottom-0')}>
                    <div className={'z-10 text-2xl  display-inline bg-tv-red-dark shadow-floating-title-tv-red'}>{_data.name}</div>
                    {_data.position && <div className={'z-10  text-base  display-inline bg-tv-purple shadow-floating-title-tv-purple'}>{_data.position}</div>}
                </div>
            </div>

            <div className='flex-1 p-4 flex flex-col justify-between gap-4 overflow-hidden'>

                <div className='flex flex-col gap-2'>

                    {_data.description && <div className='text-xs dark:text-sm md:text-sm md:dark:font-semibold content ckecontent' dangerouslySetInnerHTML={{ __html: _data.description }} />}

                </div>

                {/* <button disabled={getButtonConfig().disabled} className='bg-tv-purple hover:bg-tv-yellow disabled:bg-tv-gray-dark text-white group-hover:text-black w-full p-4 flex items-center justify-center transition group disabled:cursor-not-allowed' onClick={() => props.onVote()} >
                    <div className='flex flex-row items-center justify-center text-sm dark:text-base'>
                        {getButtonConfig().label}
                        <DynamicIcon className='text-sm dark:text-sm mb-0.5 ml-2' iconName='FaVoteYea' />
                    </div>
                </button> */}

            </div>

        </div>
    )
}