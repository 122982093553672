import nBossConnection from '@/services/nBossConnection.js';

class MapControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'mapControl';
    }

    getProjectList() {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getProjectList`, {}).then(response => resolve(response.data)).catch(reject);
        });
    }

    getProject(key) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getProject`, { key }).then(response => resolve(response.data)).catch(reject);
        });
    }
}

export default new MapControl();
