
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';

import DynamicIcon from '@/elements/DynamicIcon.js';

export default function MultiLevelMenu(props) {
    var { className, data, firstLevelKey, ...other } = props;

    return (
        <div className={className || ''}>
            {(data && data[firstLevelKey]) && data[firstLevelKey].map((item) => <MultiLevelMenuItem key={item.id} data={item} {...other} />)}
        </div>
    );
}

function MultiLevelMenuItem(props) {
    const [pathParts, setPathParts] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const levelInsetBase = props.levelInsetBase || 0.5;

    const baseClassName = props.baseClassName || '';
    const mainClassName = props.mainClassName || '';
    const subClassName = props.subClassName || '';

    const activeClassName = props.activeClassName || '';

    const orbClassName = props.orbClassName || '';
    const iconClassName = props.iconClassName || '';

    let location = useLocation();

    const getPathParts = (currentLocation) => {
        return currentLocation.pathname.substring(1).split('/')/*.splice(props.baseLevel)*/;
    }

    useEffect(() => {
        setPathParts(getPathParts(location));
    }, [location])

    useEffect(() => {
        if (pathParts.indexOf(props.data.path) > -1)
            setDropdownOpen(true);
        else
            setDropdownOpen(false);
    }, [pathParts, props.data.path])

    var { className, data, ...other } = props;

    return (
        <div className={(props.className || '')}>

            <div className='w-full border-b border-tv-gray border-opacity-50 py-2 flex flex-row items-center justify-center' >

                <NavLink
                    to={props.data.fullPath}
                    onClick={() => {
                        if (props.onChildExistClick && props.onNoChildExistClick)
                            props.data.childList ? props.onChildExistClick() : props.onNoChildExistClick();
                    }}
                    activeClassName={activeClassName}
                    className={'flex-1 ' + baseClassName + ' ' + ((props.data.level - props.baseLevel) > (props.baseLevel + 1) ? subClassName : mainClassName)}>
                    {props.data.title}
                </NavLink>

                <motion.div
                    className='relative h-6 w-6 ml-2'
                    initial='closed'
                    animate={(dropdownOpen && props.data.childList) ? 'open' : 'closed'}
                    variants={{ open: { rotate: 180 }, closed: { rotate: 0 } }}>

                    <motion.div
                        className={'absolute h-6 w-6 rounded-full ' + orbClassName}
                        initial='closed'
                        animate={(dropdownOpen && props.data.childList) ? 'open' : 'closed'}
                        variants={{ open: { opacity: 1 }, closed: { opacity: 0.3 } }} />

                    <div className='absolute h-6 w-6 flex items-center justify-center'>
                        <DynamicIcon className={'text-xs ' + iconClassName} iconName={props.data.childList ? 'FaChevronDown' : 'FaChevronRight'} />
                    </div>

                    <div className='absolute h-6 w-6 flex items-center justify-center'>
                        {/* console.log(); */}
                        {props.data.childList ? <button className='h-full w-full' onClick={() => {
                            if (props.onChildExistClick && props.onNoChildExistClick)
                                props.data.childList ? props.onChildExistClick() : props.onNoChildExistClick();
                            setDropdownOpen(!dropdownOpen)
                        }} /> : <Link to={props.data.fullPath} className='h-full w-full' />}
                    </div>

                </motion.div>

            </div>

            {props.data.childList ?
                <motion.div
                    className='w-full flex-col'
                    style={{ paddingLeft: levelInsetBase * (props.data.level - /*props.baseLevel*/0) + 'em' }}
                    initial='hidden'
                    animate={dropdownOpen ? 'visible' : 'hidden'}
                    variants={{ visible: { opacity: 1, x: 0, display: 'flex' }, hidden: { opacity: 0, x: 20, transitionEnd: { display: 'none' } } }}>
                    {props.data.childList.map((item, index) => (<MultiLevelMenuItem key={index} data={item} {...other} />))}
                </motion.div> : null}

        </div>
    );
}