import nBossConnection from '@/services/nBossConnection.js';

class FormFrontendControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'formFrontendControl';
    }

    /**
     * @param {key} key
     * @param {questionIndex} questionIndex
     */
    getForm(key, questionIndex) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getForm`, { key, questionIndex }).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
     * @param {Object} result
     */
    submit(result) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/submit`, { result }).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new FormFrontendControl();
