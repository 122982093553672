import React, { useState } from 'react';
import Sticky from 'react-stickynode';

import Tag from '@/elements/Tag.js';
import GalleryLightbox from '@/elements/lightbox/GalleryLightbox.js';
import ShareButton from '@/elements/ShareButton.js';
import DynamicIcon from '@/elements/DynamicIcon.js';

import globalConfig from '@/config/globalConfig.js'
import { getDateString } from '@/utils/GenericUtils';
import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

export default function Content(props) {
    const [galleryOpen, setGalleryOpen] = useState(false);

    const combineGalleryWithImage = (image, gallery) => {
        let _gallery = [];
        if (image)
            _gallery.push(image);
        if (gallery && gallery.contentList)
            _gallery = _gallery.concat(gallery.contentList);
        return _gallery.length > 0 ? _gallery : null;
    };

    let _data = {
        creationDate: props.data && props.data.value.publicCreationDate,
        author: (props.data && props.data.value.publicAuthor) && props.data.value.publicAuthor.name,
        photographer: getValueFromParameterMap(props.data.value, 'photographer'),
        tagList: getValueFromParameterMap(props.data.value, 'tagList'),
        image: getValueFromParameterMap(props.data.value, 'coverImage'),
        title: getValueFromParameterMap(props.data.value, 'heading'),
        subTitle: getValueFromParameterMap(props.data.value, 'subHeading'),
        lead: getValueFromParameterMap(props.data.value, 'lead'),
        content: getValueFromParameterMap(props.data.value, 'content'),
        gallery: getValueFromParameterMap(props.data.value, 'gallery') && getValueFromParameterMap(props.data.value, 'gallery').contentList,
        combinedGallery: combineGalleryWithImage(getValueFromParameterMap(props.data.value, 'coverImage'), getValueFromParameterMap(props.data.value, 'gallery')),
    }

    // const handleSectionOnClick = (e) => {
    //     if (e.target.tagName === 'IMG')
    //         setGalleryOpen(true);
    // };

    return (
        <div className={'w-full flex flex-col ' + (props.className || '')}>

            {_data.image && <ArticleImage image={_data.image} onImageClick={() => _data.gallery && setGalleryOpen(!galleryOpen)} />}

            <div className='w-full flex flex-col items-center z-10 bg-fill-base'>

                <div className='w-full p-4 md:p-8' >

                    {(props.data.value.contentType && props.data.value.contentType === "news") && <ContentHeader data={_data} textClassName='text-xs dark:text-sm' className='mb-4' />}

                    <div className='content ckecontent '>
                        {_data.title && <h1>{_data.title}</h1>}
                        {_data.subTitle && <h2>{_data.subTitle}</h2>}
                        {_data.lead && <section className='lead' dangerouslySetInnerHTML={{ __html: _data.lead }} />}
                        {_data.content && <section /*onClick={(e) => handleSectionOnClick(e)}*/ dangerouslySetInnerHTML={{ __html: _data.content }} />}

                        {_data.gallery && <button className='w-full mt-4 rounded transition bg-tv-red dark:bg-tv-purple hover:bg-tv-yellow dark:hover:bg-white text-white hover:text-tv-gray-darkest px-4 py-3 flex flex-row items-center justify-center' onClick={() => setGalleryOpen(!galleryOpen)}>
                            <DynamicIcon className='text-base dark:text-base mr-2' iconName={'FaImages'} />
                            <span className='font-bold mr-2'>Galéria megnyitása</span>
                            <span className='opacity-75'>{'(' + _data.combinedGallery.length + ' kép)'}</span>
                        </button>}
                    </div>

                </div>

            </div>

            <GalleryLightbox open={galleryOpen} onClose={() => setGalleryOpen(!galleryOpen)} gallery={_data.combinedGallery} />

        </div>
    );
}

function ArticleImage(props) {
    return (
        <Sticky top={globalConfig.etc.headerStickyOffset}>
            <div className='w-full bg-center bg-no-repeat bg-cover bg-tv-gray-lighter bg-opacity-50' style={{ backgroundImage: 'url(' + props.image.downloadUrl + ')' }} onClick={() => props.onImageClick()}> {/* h-48 sm:h-64 md:h-96 */}
                <svg className='invisible' viewBox='0 0 16 9' />
                {props.image.copyright && <div className='absolute bottom-0 right-0 px-4 py-2 bg-white dark:bg-tv-yellow bg-opacity-50 dark:bg-opacity-100 sm:bg-opacity-75 text-xxs sm:text-xs w-full sm:w-auto'>{props.image.copyright}</div>}
            </div>
        </Sticky>
    )
}

function ContentHeader(props) {
    const textClassName = props.textClassName || 'date';
    const hasTagList = props.data.tagList && props.data.tagList.value && props.data.tagList.value.length > 0;

    return (
        <div className={'flex justify-between border-b pb-2 text-color-base ' + (props.className || '')}>

            <div className={'flex flex-row items-center gap-2 ' + textClassName}>

                {hasTagList && <div className='flex flex-row flex-wrap gap-1 pt-1'>{props.data.tagList.map((item, index) => <Tag key={index} data={item} />)}</div>}

                {props.data.creationDate && <>
                    {hasTagList && <Separator />}
                    <div className={'flex flex-row items-center'}><DynamicIcon className={'mr-1.5'} iconName='FaRegCalendar' /><time>{getDateString(new Date(props.data.creationDate))}</time> </div>
                </>}

                {props.data.author && <>
                    <Separator className={'hidden sm:flex'} />
                    <div className={'hidden sm:flex flex-row items-center'}><span>Szerző:&nbsp;</span>{props.data.author}</div>
                </>}

                {(props.data.photographer && props.data.photographer !== '') && <>
                    <Separator className={'hidden sm:flex'} />
                    <div className={'hidden sm:flex flex-row items-center'}><span>Fotó:&nbsp;</span>{props.data.photographer}</div>
                </>}

            </div>

            <ShareButton
                labelClassName={textClassName}
                image={props.data.image ? props.data.image.downloadUrl : null}
                title={props.data.title}
                content={props.data.lead}
                url={window.location.href}
            />

        </div >
    );
}

function Separator(props) { return (<div className={'h-full border-l ' + (props.className || '')} />) }