import React from 'react';
import ActiveNavLink from '@/elements/ActiveNavLink.js';
import Sticky from 'react-stickynode';

import swiperConfig from '@/config/swiperConfig.js'

export default function NavigationBar(props) {
    // const [sticky, setSticky] = useState(false);

    return (
        <Sticky innerActiveClass='shadow' /*onStateChange={s => { setSticky(Sticky.STATUS_FIXED === s) }}*/>
            <div className={(props.className || '')}>

                <div className='w-full bg-tv-gray-light dark:bg-tv-purple h-12 px-4 flex items-center justify-between gap-2'>

                    {swiperConfig.screenList.map((item, index) => <NavButton key={index} data={item} icon={item.icon} activeIcon={item.activeIcon} to={{ pathname: item.pathname, hash: (item.hash ? ('#' + item.hash) : null) }} />)}

                    <NavButton icon='/assets/images/icon/tv/gray-dark/22.svg' onClick={() => props.onSliderOpen()} />

                </div>

            </div>
        </Sticky>
    );
}

function NavButton(props) {
    const buttonClassName = 'w-8 h-8 flex items-center justify-center';
    if (props.to) {
        return (
            <PathNavLink strict exact to={props.to} className={buttonClassName} >
                {({ isActive }) => isActive ? <NavButtonIcon active {...props} /> : <NavButtonIcon {...props} />}
            </PathNavLink>
        )
    } else {
        return (
            <button className={buttonClassName + ' px-1'} onClick={props.onClick ? props.onClick : null}>
                <NavButtonIcon {...props} />
            </button>
        )
    }
}

function NavButtonIcon(props) {
    return (<img className={'w-full h-full py-1'} alt='icon' src={props.active ? props.activeIcon : props.icon} />);
}

const PathNavLink = props => (
    <ActiveNavLink isActive={(_, { pathname, hash }) => (
        ((!props.to.hash && !hash && pathname === props.to.pathname) || (hash === props.to.hash && pathname === props.to.pathname))
    )} {...props} />
)