import React, { useState, useEffect } from 'react';
import Sticky from 'react-stickynode';

import OverlayHover from '@/elements/OverlayHover.js';

import CmsFrontendControl from '@/services/CmsFrontendControl.js';

import globalConfig from '@/config/globalConfig.js'
import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

export default function PublicationLister(props) {
    const [year, setYear] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.listData)
            setYear(getYearList(props.listData.value)[0]);
    }, [props.listData])

    useEffect(() => {
        year ? CmsFrontendControl.getList(year.key, 0, 0).then(result => { setData(result) }) : setData(null);
    }, [year])

    useEffect(() => {
        if (props.data)
            setData(props.data)
    }, [props.data])

    const getYearList = (data) => {
        let arr = [];
        for (var key in data)
            arr.push({ 'year': key, 'key': data[key] });
        return arr.reverse();
    }

    return (
        <div className={'w-full flex flex-col ' + (props.className || '')}>

            <Sticky top={globalConfig.etc.headerStickyOffset}>
                <div className='w-full bg-tv-red p-4 md:p-8 flex flex-col md:flex-row sm:items-center gap-4 bg-no-repeat bg-bottom bg-cover content' style={{ backgroundImage: 'url(/assets/images/pattern-red.png)' }} >

                    <div className='w-full flex flex-col gap-2 text-white flex-1'>
                        <h1>{getValueFromParameterMap(props.contentData.value, 'heading')}</h1>
                        {getValueFromParameterMap(props.contentData.value, 'content') && <div dangerouslySetInnerHTML={{ __html: getValueFromParameterMap(props.contentData.value, 'content') }} />}
                    </div>

                    {props.listData && <div className='w-full md:w-auto grid grid-cols-4 sm:grid-cols-6 gap-2'>
                        {getYearList(props.listData.value).map((item) => (
                            <button
                                className={'shadow-lg py-2 sm:py-1 px-2 font-bold text-xs transition-all hover:bg-tv-yellow text-white hover:text-black ' + ((year && (year.key === item.key)) ? 'bg-tv-purple-dark' : 'bg-tv-purple ')}
                                key={item.year}
                                onClick={() => { setYear(item) }}>
                                {item.year}
                            </button>
                        ))}
                    </div>}

                </div>
            </Sticky>

            {data ? <div className='w-full bg-fill-base z-10 p-4 md:p-8 grid grid-cols-2 md:grid-cols-3 gap-4'>{
                data.contentList.map((item) => (
                    <PublicationItem key={item.id} data={item} yearVisible={props.listData ? true : false} />
                ))}
            </div> : null}

        </div >
    )
}

function PublicationItem(props) {
    const [hover, setHover] = useState(null);

    let _data = {
        image: (getValueFromParameterMap(props.data, 'coverImage') && !props.disableImage) && getValueFromParameterMap(props.data, 'coverImage').downloadUrl,
        imageUrl: !props.disableImage && getValueFromParameterMap(props.data, 'coverImageUrl'),
        file: (getValueFromParameterMap(props.data, 'file')) && getValueFromParameterMap(props.data, 'file').downloadUrl,
        fileUrl: getValueFromParameterMap(props.data, 'fileUrl'),
        year: getValueFromParameterMap(props.data, 'dateYear'),
        month: getValueFromParameterMap(props.data, 'dateMonth'),
        name: getValueFromParameterMap(props.data, 'name'),
    }

    return (
        <div className='flex flex-col sm:p-3 bg-tv-gray-light dark:bg-tv-yellow shadow-xl'>

            <a
                href={_data.file || _data.fileUrl}
                target='_blank'
                rel='noreferrer'
                className='relative bg-tv-gray-light shadow-xl'
                onMouseEnter={e => setHover(true)}
                onMouseLeave={e => setHover(false)}>

                <svg className='invisible' viewBox='0 0 51 73' />

                <div className='absolute -inset-0 w-full h-full'>

                    <div className='w-full h-full bg-tv-gray bg-center bg-no-repeat bg-cover ' style={{ backgroundImage: 'url(' + (_data.image || _data.imageUrl) + ')' }} />

                    <OverlayHover hover={hover} iconName={'FaDownload'} title={_data.name} subTitle={props.yearVisible && (_data.year + '.' + (_data.month ? (' ' + _data.month) : ''))} label={'Letöltés'} />

                </div>

            </a>

            {props.yearVisible && <div className='w-full bg-tv-red dark:bg-tv-purple text-white text-center text-xs dark:text-sm font-semibold dark:font-bold p-2'>{_data.year + '.' + (_data.month ? (' ' + _data.month) : '')}</div>}

        </div>
    )
}