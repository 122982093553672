import React from 'react';
import { NavLink } from 'react-router-dom';

import DynamicIcon from '@/elements/DynamicIcon.js';

export default function OrbButton(props) {
    let className = 'group transform transition hover:scale-110 ' + (props.className || '');

    if (props.to)
        return (<NavLink className={className} to={props.to} title={props.title}><OrbButtonChildren {...props} /></NavLink>);
    else if (props.href)
        return (<a className={className} href={props.href} target={props.target} rel='noreferrer' title={props.title}><OrbButtonChildren {...props} /></a>);
    else
        return (<button className={className} onClick={props.onClick} title={props.title}><OrbButtonChildren {...props} /></button>);
}

function OrbButtonChildren(props) {
    const getPrefixedClassName = (prefix, className) => {
        let hoverClassName = '', parts = className.split(' ');
        for (var i = 0; i < parts.length; i++) {
            if (i !== 0)
                hoverClassName += ' ';
            hoverClassName += prefix + parts[i];
        }
        return hoverClassName;
    }

    return (
        <div className={'w-full h-full relative'}>

            <span className={'absolute top-0 left-0 w-full h-full rounded-full transition '
                + (props.data && props.data.backgroundClassName ? props.data.backgroundClassName : '')
                + ' ' + (props.data && props.data.backgroundHoverClassName ? getPrefixedClassName('group-hover:', props.data.backgroundHoverClassName) : '')} />

            <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center'>
                <DynamicIcon className={'text-sm ' + (props.data && props.data.iconClassName ? props.data.iconClassName : '')} iconName={props.data.iconName} />
            </div>

        </div>
    );
}