import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

import DictionaryText from '@/elements/DictionaryText';
import Modal, { ModalButton, ModalHeader, ModalContent } from '@/elements/modal/Modal';

import PmFrontendControl from '@/services/PmFrontendControl.js';

import { getTranslation } from '@/utils/DictionaryUtils.js';

export default function ModalNewsletter(props) {
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(null);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);

    const [termsAccepted, setTermsAccepted] = useState(false);

    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [feedback, setFeedback] = useState(false);

    const send = () => {
        PmFrontendControl.subscribe(props.id, name, email).then(result => {
            if (result.successful)
                setFeedback(true);
            else {
                if (result.properties && result.properties.name)
                    setNameError(result.properties.name[0].message);
                if (result.properties && result.properties.email)
                    setEmailError(result.properties.email[0].message);
            }
        });
    };

    useEffect(() => {
        if (name !== '' && email !== '' && termsAccepted)
            setButtonEnabled(true);
        else
            setButtonEnabled(false);
    }, [name, email, termsAccepted]);

    useEffect(() => {
        setNameError(null);
    }, [name]);

    useEffect(() => {
        setEmailError(null);
    }, [email]);

    return (
        <Modal {...props}>

            <ModalHeader {...props}><DictionaryText textKey={'newsletter-subscription'} /></ModalHeader>

            <ModalContent {...props}>

                {!feedback ?
                    <>

                        <div className='text-sm content'>{getTranslation('newsletter-description')}</div>

                        <div className='flex flex-col gap-2'>
                            <input className={'generic-input ' + (nameError ? 'generic-input-error' : '')}
                                type='text'
                                name='name'
                                placeholder={getTranslation('name') + ' ...'}
                                value={name}
                                onChange={e => setName(e.target.value)} />

                            <input className={'generic-input ' + (emailError ? 'generic-input-error' : '')}
                                type='email'
                                name='email'
                                placeholder={getTranslation('email-address') + ' ...'}
                                value={email}
                                onChange={e => setEmail(e.target.value)} />

                            <div className='border dark:border-0 p-4 text-xs dark:text-sm flex flex-row gap-4 items-center justify-center dark:text-white'>
                                <input type='checkbox' onChange={e => setTermsAccepted(e.target.checked)} />
                                <span>Az <a className='text-tv-red dark:text-tv-yellow font-semibold' href='/oldal/adatkezelesi-tajekoztato' target='_blank'>Adatkezelési tájékoztatót</a> elolvastam, a benne foglaltakat megismertem, az adatkezelési hozzájárulást megadom.</span>
                            </div>
                        </div>

                        <ModalButton label={getTranslation('newsletter-subscription')} onClick={() => send()} disabled={!buttonEnabled} />

                    </>

                    :

                    <>

                        <div className='text-sm content'>{getTranslation('newsletter-feedback')}</div>

                        <ModalButton label={getTranslation('close')} onClick={() => props.onClose()} />

                    </>
                }

            </ModalContent>

        </Modal>
    );
}