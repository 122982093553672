import nBossConnection from '@/services/nBossConnection.js';

class VisitorUserLogin {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'visitorUserLogin';
    }

    /**
  * @param {email} email
  * @param {password} password
  */
    login(email, password) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/login`, { email, password }).then(response => resolve(response.data)).catch(reject);
        });
    }

    logout() {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/logout`, {}).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new VisitorUserLogin();
