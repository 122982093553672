import React, { useRef, useState, useEffect } from 'react';
import { EventRegister } from '@/utils/EventRegister.js';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import TopBar from '@/components/topbar/TopBar.js';
import MenuSlider from '@/components/menuslider/MenuSlider.js';
import NavBar from '@/components/navbar/NavBar.js';

import SearchInput from '@/elements/SearchInput.js';
import LangSelector from '@/elements/LangSelector.js';
import AuthIndicator from '@/elements/AuthIndicator.js';
import ModalAuth from '@/elements/modal/ModalAuth';

import { getWebsite } from '@/utils/WebsiteUtils.js';
import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

export default function Header(props) {
    const [authModalVisible, setAuthModalVisible] = useState(false);
    const [mainPage, setMainPage] = useState(null);
    const [sliderOpen, setSliderOpen] = useState(false);
    const [fillSearchOpen, setFillSearchOpen] = useState(false);

    let location = useLocation();


    useEffect(() => {
        setMainPage(location.pathname === '/');
    }, [location]);

    useEffect(() => {
        let userAuthNeededEvent = EventRegister.addEventListener('userAuthNeeded', (data) => { setAuthModalVisible(true) });
        return () => EventRegister.removeEventListener(userAuthNeededEvent)
    }, []);

    const conatiner = useRef();
    const leftSidebar = useRef();
    const rightSidebar = useRef();

    useEffect(() => {
        if (leftSidebar.current && rightSidebar.current && conatiner.current) {
            let resizeObserver = new ResizeObserver(() => {
                if (leftSidebar.current)
                    leftSidebar.current.style.minWidth = 'initial';
                if (rightSidebar.current)
                    rightSidebar.current.style.minWidth = 'initial';

                if (rightSidebar.current.offsetWidth > leftSidebar.current.offsetWidth)
                    leftSidebar.current.style.minWidth = rightSidebar.current.offsetWidth + 'px';
                else if (leftSidebar.current.offsetWidth > rightSidebar.current.offsetWidth)
                    rightSidebar.current.style.minWidth = leftSidebar.current.offsetWidth + 'px';
            });
            resizeObserver.observe(conatiner.current);
        }
    }, [leftSidebar, rightSidebar, conatiner])

    return (
        <header className={'w-full relative ' + (props.className || '')}>

            <div className='block sm:hidden'>
                <MenuSlider className='flex sm:hidden' open={sliderOpen} onSliderClose={() => setSliderOpen(false)} />
            </div>

            <img className='opacity-0 md:opacity-100 absolute -top-8 right-0 h-24 -z-1 dark:hidden' alt='' src='/assets/images/header/header-artifact.png' />

            <ContactBar className='hidden sm:flex z-10' />

            <div className='relative flex mx-0 sm:mx-6 my-3 sm:my-6' ref={conatiner}>

                <motion.div
                    ref={leftSidebar}
                    className='flex items-center justify-start md:justify-start flex-1'
                    initial={fillSearchOpen ? 'hidden' : 'visible'}
                    animate={fillSearchOpen ? 'hidden' : 'visible'}
                    variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}>

                    <button className='block sm:hidden h-10 mx-4' onClick={() => setFillSearchOpen(true)} >
                        <img className='w-full h-full p-2 hidden dark:block' alt='search' src='/assets/images/icon/search-white.svg' />
                        <img className='w-full h-full p-2 block dark:hidden' alt='search' src='/assets/images/icon/search-black.svg' />
                    </button>

                    <Link to='/' className='hidden sm:block w-15 h-16 mx-0'>
                        <img className='w-full h-full block dark:hidden' alt='logo' src='/assets/images/logo/shield-text-top-outline-black.svg' />
                        <img className='w-full h-full hidden dark:block' alt='logo' src='/assets/images/logo/shield-text-top-outline-white.svg' />
                    </Link>

                </motion.div>

                <motion.div
                    className='max-w-tv-boxed flex items-center md:items-end justify-center flex-tv-boxed-content'
                    initial={fillSearchOpen ? 'hidden' : 'visible'}
                    animate={fillSearchOpen ? 'hidden' : 'visible'}
                    variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}>

                    <Link to='/'>
                        <img className='h-7 sm:h-11 block dark:hidden' alt='logo' src='/assets/images/logo/text-fill-red.svg' />
                        <img className='h-7 sm:h-11 hidden dark:block' alt='logo' src='/assets/images/logo/text-fill-white.svg' />
                    </Link>

                </motion.div>

                <motion.div
                    ref={rightSidebar}
                    className='flex items-center md:items-end justify-center md:justify-end flex-1'
                    initial={fillSearchOpen ? 'hidden' : 'visible'}
                    animate={fillSearchOpen ? 'hidden' : 'visible'}
                    variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}>

                    {getValueFromParameterMap(getWebsite().globalContent, 'lang-change-enabled') && <LangSelector className='flex sm:hidden mx-4' labelClassName={'text-xs uppercase font-bold tracking-wide dark:text-white'} currentLocale={props.currentLocale} availableLocales={props.availableLocales} />}

                    <button className='hidden sm:block md:hidden w-10 h-10 mx-4' onClick={() => setFillSearchOpen(true)} >
                        <img className='w-full h-full p-1 block dark:hidden' alt='search' src='/assets/images/icon/search-black.svg' />
                        <img className='w-full h-full p-1 hidden dark:block' alt='search' src='/assets/images/icon/search-white.svg' />
                    </button>

                    <SearchInput className='hidden md:flex w-full max-w-48 min-w-36' />

                </motion.div>

                <SearchInput className='absolute top-0 left-0 sm:py-2 w-full h-full flex md:hidden' fill open={fillSearchOpen} onClose={() => setFillSearchOpen(false)} />

            </div>

            {mainPage !== null && <TopBar className={mainPage ? 'block' : 'hidden sm:block'} mainPage={mainPage} />}

            <NavBar className='block sm:hidden' onSliderOpen={() => setSliderOpen(true)} />

            {authModalVisible && <ModalAuth onClose={() => { setAuthModalVisible(false); }} />}

        </header>
    );
}

function ContactBar(props) {
    const getData = () => {
        let dataList = [
            { 'labelKey': 'address', 'valueKey': 'address-link' },
            { 'labelKey': 'phone-number', 'valuePrefix': 'tel:' },
            { 'labelKey': 'email', 'valuePrefix': 'mailto:' }
        ];

        const contactConfig = getValueFromParameterMap(getWebsite().globalContent, 'contacts');

        for (let key in dataList) {
            dataList[key]['label'] = getValueFromParameterMap(contactConfig, dataList[key]['labelKey']);
            if (dataList[key]['valueKey'])
                dataList[key]['value'] = getValueFromParameterMap(contactConfig, dataList[key]['valueKey']);
            if (dataList[key]['valuePrefix'])
                dataList[key]['value'] = dataList[key]['valuePrefix'] + dataList[key]['label'];
        }

        return dataList;
    }

    const labelClassName = 'text-xxs dark:text-xs font-semibold dark:font-bold dark:text-white transition hover:text-tv-red dark:hover:text-tv-yellow uppercase tracking-wider px-2'

    return (
        <div className={props.className + ' relative md:absolute w-full top-0 left-0 py-0 md:py-2 h-6 md:h-auto bg-tv-gray-light md:bg-transparent dark:bg-transparent items-center'}>

            <div className={'w-full items-center justify-center'}>
                <div className='flex flex-row justify-center divide-x divide-tv-red dark:divide-white'>
                    {getData().map((item, index) => <div className={labelClassName} key={index}><a href={item.value}>{item.label}</a></div>)}
                    {getValueFromParameterMap(getWebsite().globalContent, 'lang-change-enabled') && <LangSelector dropdown labelClassName={labelClassName} currentLocale={props.currentLocale} availableLocales={props.availableLocales} />}
                    {/* <AuthIndicator className={labelClassName} /> */}
                </div>
            </div>

        </div>
    );
}