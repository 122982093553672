import React, { useState, useEffect } from 'react';
import { EventRegister } from '@/utils/EventRegister.js';

import DynamicIcon from '@/elements/DynamicIcon.js';
import DictionaryText from '@/elements/DictionaryText';
import Modal, { ModalButton, ModalHeader, ModalContent } from '@/elements/modal/Modal';

import VisitorAccessManagement from '@/services/VisitorAccessManagement.js';
import VisitorUserLogin from '@/services/VisitorUserLogin.js';
import VisitorUserRegistrationControl from '@/services/VisitorUserRegistrationControl.js';

import { getTranslation } from '@/utils/DictionaryUtils.js';
import { setUser, getUser } from '@/utils/UserUtils.js';

export default function ModalAuth(props) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [login, setLogin] = useState(true);

    const [userName, setUserName] = useState('');
    const [userNameError, setUserNameError] = useState(null);

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);

    const [passwordAgain, setPasswordAgain] = useState('');
    const [passwordAgainError, setPasswordAgainError] = useState(null);

    const [fullName, setFullName] = useState('');
    const [fullNameError, setFullNameError] = useState(null);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(null);

    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(null);

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsAcceptedError, setTermsAcceptedError] = useState(false);

    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [feedback, setFeedback] = useState(false);

    const doLogout = () => {
        VisitorUserLogin.logout().then(result => {
            setUser(null);
        });
    };

    const doLogin = () => {
        VisitorUserLogin.login(email, password).then(result => {
            if (result) {
                VisitorAccessManagement.getLoggedInClient().then(result => { setUser(result); });
                //setFeedback(true);
            } else {
                setEmailError(true);
                setPasswordError(true);
            }
        });
    };

    const doRegistration = () => {
        VisitorUserRegistrationControl.save({ userName, email, password, passwordAgain, fullName, phoneNumber, address }).then(result => {
            if (result.successful)
                setFeedback(true);
            else {
                if (result.properties && result.properties.userName) setUserNameError(result.properties.name[0].message);
                if (result.properties && result.properties.email) setEmailError(result.properties.email[0].message);
                if (result.properties && result.properties.password) setPasswordError(result.properties.password[0].message);
                if (result.properties && result.properties.passwordAgain) setPasswordAgainError(result.properties.passwordAgain[0].message);
                if (result.properties && result.properties.fullName) setFullNameError(result.properties.fullName[0].message);
                if (result.properties && result.properties.phoneNumber) setPhoneNumberError(result.properties.phoneNumber[0].message);
                if (result.properties && result.properties.address) setAddressError(result.properties.address[0].message);
            }
        });
    };

    useEffect(() => {
        setLoggedIn(getUser() ? true : false)
        let userChangedEvent = EventRegister.addEventListener('userChanged', (data) => { setLoggedIn(data ? true : false); });
        return () => EventRegister.removeEventListener(userChangedEvent)
    }, []);

    useEffect(() => {
        if (feedback) {
            setButtonEnabled(false);
        } else if (login) {
            if (email !== '' && password !== '')
                setButtonEnabled(true);
            else
                setButtonEnabled(false);
        } else {
            if (userName !== '' && email !== '' && password !== '' && passwordAgain !== '' && termsAccepted)
                setButtonEnabled(true);
            else
                setButtonEnabled(false);
        }
    }, [email, feedback, login, password, passwordAgain, termsAccepted, userName]);

    useEffect(() => {
        if (login)
            setFeedback(false);
    }, [login]);

    useEffect(() => { setUserNameError(null); }, [userName]);
    useEffect(() => { setEmailError(null); }, [email]);
    useEffect(() => { setPasswordError(null); }, [password]);
    useEffect(() => { setPasswordAgainError(null); }, [passwordAgain]);
    useEffect(() => { setFullNameError(null); }, [fullName]);
    useEffect(() => { setPhoneNumberError(null); }, [phoneNumber]);
    useEffect(() => { setAddressError(null); }, [address]);
    useEffect(() => { setTermsAcceptedError(null); }, [termsAccepted]);

    return (
        <Modal {...props}>

            <ModalHeader {...props}>
                {loggedIn ? <div>{getTranslation('welcome-user') + ', ' + (getUser().fullName ? getUser().fullName : getUser().userName) + '!'}</div> : <DictionaryText textKey={login ? 'login' : 'registration'} />}
            </ModalHeader>

            <ModalContent /*className='max-h-screen overflow-y-auto'*/>

                {loggedIn && <div className='flex flex-col gap-2' >

                    <div className='text-xs'>Üdvözöljük a Terézváros.hu oldalán, jelenleg a következő felhasználói adatokkal van bejelentkezve: </div>

                    <div className='bg-tv-purple text-white p-4 flex flex-col gap-2'>
                        <ProfileLine title='Felhasználónév' value={getUser().userName} icon='FaUser' />
                        <ProfileLine title='E-mail cím' value={getUser().email} icon='FaLock' />
                    </div>

                </div>}

                {(feedback && !loggedIn) && <>

                    {login ?
                        <div>Sikeres bejelentkezés!</div>
                        :
                        // <div className='text-xs'>Gratulálunk, sikeresen regisztrált a Terézváros.hu oldalára!<br /><br />Kérjük, ellenőrizze e-mail fiókját, és megerősítő e-mail-ben található aktiváló link segítségével <strong>aktiválja fiókját.</strong><br /><br />Az aktivációt követően a "Bejelentkezés" gombra kattintva tud belépni fiókjába.</div>
                        <div className='text-xs'>Gratulálunk, sikeresen regisztrált a Terézváros.hu oldalára! <br/><br/>A "Bejelentkezés" gombra kattintva tud belépni fiókjába.</div>
                    }

                </>}

                {(!feedback && !loggedIn) && <>

                    <div className='text-xs'>Amennyiben már regisztrált oldalunkra, a fehasználóneve és jelszava segítségével tud belépni az oldalra, amennyiben még nem regisztrált, a "Regisztráció" gombra kattintva teheti ezt meg.</div>

                    {!login && <input className={'generic-input ' + (userNameError ? 'generic-input-error' : '')}
                        type='text'
                        name='user-name'
                        placeholder={getTranslation('user-name') + (login ? '' : '*') + ' ...'}
                        value={userName}
                        onChange={e => setUserName(e.target.value)} />}

                    <input className={'generic-input ' + (emailError ? 'generic-input-error' : '')}
                        type='email'
                        name='email'
                        placeholder={getTranslation('email-address') + (login ? '' : '*') + ' ...'}
                        value={email}
                        onChange={e => setEmail(e.target.value)} />

                    <input className={'generic-input ' + (passwordError ? 'generic-input-error' : '')}
                        type='password'
                        name='password'
                        placeholder={getTranslation('password') + (login ? '' : '*') + ' ...'}
                        value={password}
                        onChange={e => setPassword(e.target.value)} />

                    {!login && <input className={'generic-input ' + (passwordAgainError ? 'generic-input-error' : '')}
                        type='password'
                        name='password-again'
                        placeholder={getTranslation('password-again') + (login ? '' : '*') + ' ...'}
                        value={passwordAgain}
                        onChange={e => setPasswordAgain(e.target.value)} />}

                    {!login && <input className={'generic-input ' + (fullNameError ? 'generic-input-error' : '')}
                        type='text'
                        name='fullName'
                        placeholder={getTranslation('full-name') + ' ...'}
                        value={fullName}
                        onChange={e => setFullName(e.target.value)} />}

                    {!login && <input className={'generic-input ' + (phoneNumberError ? 'generic-input-error' : '')}
                        type='text'
                        name='phoneNumber'
                        placeholder={getTranslation('phone') + ' ...'}
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)} />}

                    {!login && <input className={'generic-input ' + (addressError ? 'generic-input-error' : '')}
                        type='text'
                        name='address'
                        placeholder={getTranslation('address') + ' ...'}
                        value={address}
                        onChange={e => setAddress(e.target.value)} />}

                    {!login && <div className='border dark:border-0 p-4 text-xs dark:text-sm flex flex-row gap-4 items-center justify-center dark:text-white'>
                        <input type='checkbox' onChange={e => setTermsAccepted(e.target.checked)} />
                        <span>Az <a className='text-tv-red dark:text-tv-yellow font-semibold' href='/oldal/adatkezelesi-tajekoztato' target='_blank'>Adatkezelési tájékoztatót</a> elolvastam, a benne foglaltakat megismertem, az adatkezelési hozzájárulást megadom.*</span>
                    </div>}

                </>}

                <div className='flex flex-col gap-2'>

                    {loggedIn && <ModalButton label={getTranslation('logout')} onClick={() => doLogout()} />}

                    {!loggedIn && <>
                        <ModalButton disabled={!buttonEnabled} label={login ? getTranslation('login') : getTranslation('registration')} onClick={() => login ? doLogin() : doRegistration()} />
                        <ModalButton secondary label={login ? getTranslation('registration') : getTranslation('login')} onClick={() => setLogin(!login)} />
                    </>}

                </div>

            </ModalContent>

        </Modal >
    );
}

function ProfileLine(props) {
    return (
        <div className='flex flex-row items-center gap-2 text-sm'>
            <DynamicIcon className='' iconName={props.icon} />
            <div className='font-bold leading-none'>{props.title + ':'}</div>
            <div className='leading-none'>{props.value}</div>
        </div>
    );
}