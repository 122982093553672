import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import Sticky from 'react-stickynode';

import Tag from '@/elements/Tag.js';
import DynamicIcon from '@/elements/DynamicIcon.js';
import DictionaryText from '@/elements/DictionaryText.js';

import { getNBossEndpoint } from '@/cms/NBossCMS.js';

import globalConfig from '@/config/globalConfig.js'
import { getQueryVariable, getSearchUrl } from '@/utils/GenericUtils.js';
import { getTranslation } from '@/utils/DictionaryUtils.js';

// import CmsFrontendControl from '@/services/CmsFrontendControl.js';
import QueryControl from '@/services/QueryControl.js';

export default function Search(props) {
    const [term, setTerm] = useState('');
    const [result, setResult] = useState(null);

    let location = useLocation();
    const history = useHistory();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter')
            search();
    }

    const search = () => {
        // if (term !== '')
        history.push(getSearchUrl(term));
    }

    // const loadMore = () => {
    //     query(term);
    // }

    const _getQueryVariable = () => {
        let _term = getQueryVariable(location.search, globalConfig.search.queryParam) || '';
        if (_term !== '')
            _term = decodeURIComponent(_term);
        return _term;
    };

    useEffect(() => {
        let _term = _getQueryVariable();

        setTerm(_term);

        if (_term !== '')
            query(_term);
        else
            setResult(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // useEffect(() => {
    //     console.log(result)
    // }, [result]);

    const query = (term) => {
        const first = result ? result.items.length : 0;
        // console.log(result)
        QueryControl.query({ 'query': term, 'firstResult': first, 'maxResult': 20, 'directoryId': 31 }).then(result => { setResult(result) });
    }

    return (
        <div className={'w-full flex flex-col min-h-full ' + (props.className || '')}>

            <Sticky top={globalConfig.etc.headerStickyOffset}>

                <div className='w-full flex flex-col sm:flex-row sm:items-stretch gap-2 sm:gap-0 p-4 py-6 sm:p-8 lg:p-10 bg-tv-red bg-no-repeat bg-center bg-cover' style={{ backgroundImage: 'url(/assets/images/pattern-red.png)' }}>

                    <h1 className='text-white text-3xl sm:text-4xl uppercase font-bold sm:mr-8'><DictionaryText textKey={'search'} /></h1>

                    <div className='w-full flex flex-row'>

                        <input className='w-full outline-none bg-white bg-opacity-25 rounded-l py-2 px-4 placeholder-white text-white'
                            type='text'
                            name='search'
                            placeholder={getTranslation('search-placeholder') + ' ...'}
                            value={term}
                            onChange={e => setTerm(e.target.value)}
                            onKeyDown={handleKeyDown} />


                        <button className='flex flex-row gap-2 items-center justify-center px-5 bg-tv-purple transition group hover:bg-tv-yellow rounded-r shadow' onClick={() => search()}>

                            <div className='w-4 h-4 relative'>
                                <img className='absolute w-full h-full transition opacity-0 group-hover:opacity-100' alt='search' src='/assets/images/icon/search-black.svg' />
                                <img className='absolute w-full h-full transition opacity-100 group-hover:opacity-0' alt='search' src='/assets/images/icon/search-white.svg' />
                            </div>

                            <div className='transition text-white group-hover:text-black hidden sm:flex'><DictionaryText textKey={'search'} /></div>

                        </button>

                    </div>

                </div>

                <motion.div
                    className='w-full bg-tv-gray-light items-center px-4 sm:px-8 lg:px-10 py-1.5'
                    style={{ originY: 0, originX: 0.5, transformPerspective: 1250 }}
                    initial={result ? 'open' : 'closed'}
                    animate={result ? 'open' : 'closed'}
                    variants={{ 'open': { rotateX: 0, display: 'flex' }, 'closed': { rotateX: -90, transitionEnd: { display: 'none' } } }}>

                    <div className='text-xxs flex flex-row divide-x divide-tv-gray dark:divide-tv-gray'>
                        <MetaItem labelKey='search-entered-term' value={_getQueryVariable()} />
                        <MetaItem labelKey='search-count' value={result ? result.count : '0'} />
                        <MetaItem labelKey='search-visible' value={result ? ('1 - ' + result.items.length) : '0'} />

                        {/* <button className='self-end'>Keresés törlése</button> */}
                    </div>

                </motion.div>

            </Sticky>

            <div className='w-full flex flex-col gap-2 sm:gap-8 p-4 sm:p-8 bg-fill-base flex-1 z-10'>

                {(result && result.count > 0) && <div className='flex flex-col divide-y'> {result.items.map((item, index) => <SearchResultItem key={index} data={item} />)}</div>}

                {(result && result.count === 0) && <div>Nincs találat.</div>}

                {!result && <div className='w-full flex-1 flex flex-col gap-1 py-6 items-center justify-center text-center'>
                    <div className='opacity-25 mb-4'>
                        <img className='h-7 sm:h-8 block dark:hidden' alt='logo' src='/assets/images/logo/text-fill-black.svg' />
                        <img className='h-7 sm:h-8 hidden dark:block' alt='logo' src='/assets/images/logo/text-fill-white.svg' />
                    </div>
                    <div className='text-md sm:text-lg font-semibold text-tv-gray'><DictionaryText textKey={'search-title'} /></div>
                    <div className='text-xs sm:text-sm text-tv-gray'><DictionaryText textKey={'search-sub-title'} /></div>
                </div>}

                {/* <button className='w-full py-3 rounded bg-tv-purple text-white flex items-center justify-center' onClick={() => loadMore()}><DictionaryText textKey={'search-more-results'} /></button> */}

            </div>

        </div >
    );
}

function MetaItem(props) {
    return (<div className='font-semibold tracking-wide px-2 first:pl-0 last:pr-0'>{getTranslation(props.labelKey) + ': '}<span className='text-tv-red font-bold'>{props.value}</span></div>);
}

function SearchResultItem(props) {
    let _data = {
        heading: props.data.name,
        content: props.data.mergedContent,
        pageUrl: props.data.page,
        fileUrl: props.data.fileKey && getNBossEndpoint() + 'fmFrontendControl/getFile?key=' + props.data.fileKey,
        entryType: props.data.entryType,
        pageType: props.data.pageType,
    }

    const getTags = () => {
        let tagList = [];
        if (_data.entryType === 'DOCUMENT') {
            tagList.push({ name: 'Dokumentum' });
        } else {
            tagList.push({ name: 'Tartalom' });
            if (_data.pageType === 'NEWS')
                tagList.push({ name: 'Hírek' });
            else if (_data.pageType === 'SIMPLE_CONTENT')
                tagList.push({ name: 'Oldal' });
            else if (_data.pageType === 'PERSON ')
                tagList.push({ name: 'Képviselői adatlap' });
        }
        return tagList;
    }

    return (
        <div className='w-full flex flex-col sm:flex-row sm:items-center gap-4 py-4 first:pt-0 last:pb-0'>

            <div className='flex-1 flex flex-col gap-2'>

                <div className='flex flex-col sm:flex-row gap-2 items-start sm:items-center'>
                    <div className='flex flex-row flex-wrap gap-1'>{getTags().map((item, index) => <Tag key={index} data={item} />)}</div>
                    {_data.heading && <h1 className='text-lg font-bold uppercase text-tv-gray-darkest'>{_data.heading}</h1>}
                </div>

                {_data.content && <p className='text-sm' dangerouslySetInnerHTML={{ __html: _data.content }} />}

            </div>

            {(_data.pageUrl || _data.fileUrl) && <Button data={_data} />}

        </div>
    );
}

function Button(props) {
    const className = 'rounded px-5 py-3 bg-tv-red hover:bg-tv-purple transition text-white text-center flex flex-row items-center justify-center gap-2';

    if (props.data.pageUrl)
        return (<Link className={className} to={props.data.pageUrl}><ButtonContent data={props.data} labelKey={'more'} icon={'FaChevronCircleRight'} /></Link>);
    else
        return (<a className={className} href={props.data.fileUrl} > <ButtonContent data={props.data} labelKey={'download'} icon={'FaFileDownload'} /></a>);
}

function ButtonContent(props) {
    return (
        <>
            <DynamicIcon className='text-sm' iconName={props.icon} />
            <DictionaryText textKey={props.labelKey} />
        </>
    );
}