import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { getWebsite } from '@/utils/WebsiteUtils.js';

import CmsFrontendControl from '@/services/CmsFrontendControl.js';

const dataClassName = 'text-xxs uppercase font-bold tracking-wide';

export default function LangSelector(props) {
    const [hover, setHover] = useState(false);

    const onLangChange = (key) => {
        CmsFrontendControl.setLocale(key).then(result => {
            // console.log(result);
            result && window.location.reload()
        });
    };

    const getShort = (item) => {
        if (item.toLowerCase() === 'angol')
            return 'En';
        else if (item.toLowerCase() === 'magyar')
            return 'Hu';
        else return item.substring(0, 2)
    };

    const getLong = (item) => {
        if (item.toLowerCase() === 'angol')
            return 'English';
        else if (item.toLowerCase() === 'magyar')
            return 'Magyar';
        else return item
    };

    if (props.dropdown) {
        return (
            <div className={'relative flex group h-full ' + (props.className || '')} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

                {getWebsite().currentLocale && <button className={(props.labelClassName || '') + ' transition dark:text-white group-hover:text-tv-red'}>{getLong(getWebsite().currentLocale.name)}</button>}

                {getWebsite().availableLocales && <motion.div
                    className='absolute -top-1 w-full overflow-hidden flex flex-col items-center gap-1 shadow-lg bg-tv-gray-light dark:bg-tv-purple py-1'
                    initial='closed'
                    animate={hover ? 'open' : 'closed'}
                    variants={{ open: { opacity: 1, height: 'auto' }, closed: { opacity: 0, height: 0 } }}>
                    {getWebsite().availableLocales.map((item, index) => (
                        <button key={index} className={(props.labelClassName || '') + ' transition hover:text-tv-red ' + (getWebsite().currentLocale.key === item.key ? 'order-1' : 'order-2')} onClick={() => onLangChange(item.key)}>{getLong(item.name)}</button>
                    ))}
                </motion.div>}

            </div>
        )
    } else {
        return (
            <div className={'flex flex-row ' + (props.className || '')}>
                {getWebsite().availableLocales.map((item, index) => (
                    <div className='flex flex-row' key={index}>
                        {index ? <span className={(props.labelClassName || dataClassName) + ' mx-1'}>|</span> : null}
                        <button className={props.labelClassName || dataClassName} onClick={() => onLangChange(item.key)}>{getShort(item.name)}</button>
                    </div>
                ))}
            </div>
        )
    }
}