import React from 'react';

import DictionaryText from '@/elements/DictionaryText';

import useDarkMode from '@/hooks/useDarkMode.js';

export default function DarkModeSwitch(props) {
    const [colorTheme, setTheme] = useDarkMode();

    return (
        <button
            className={'flex flex-row items-center justify-center ' + (props.className || '')}
            onClick={() => { setTheme(colorTheme) }}>

            {/* {props.label && <div className={'pl-2 pr-1 dark:pr-2 ' + (props.labelClassName || '')}>{props.label}</div>} */}

            {props.label && <div className={'pl-2 pr-1 dark:pr-2 ' + (props.labelClassName || '')}><DictionaryText textKey={'accessible-version'} /></div>}

            <img className='w-5 h-5 bg-tv-gray-darkest rounded-full bg-opacity-10 dark:bg-opacity-100 p-0.5 ' alt='search' src='/assets/images/icon/accessibility.svg' />

        </button>
    );
}