import nBossConnection from '@/services/nBossConnection.js';

class PmFrontendControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'pmFrontendControl';
    }

    /**
     * @param {id} id
     * @param {name} name
     *  @param {email} email
     */
    subscribe(id, name, email) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/subscribe`, { id, name, email }).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new PmFrontendControl();
