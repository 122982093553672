import React, { } from 'react';
import { motion } from 'framer-motion';

import DynamicIcon from '@/elements/DynamicIcon.js';

export default function OverlayHover(props) {
    const yOffset = 30;

    return (
        <motion.div
            // transition={transition}
            className='w-full h-full absolute top-0 left-0 px-4 bg-tv-gray-dark bg-opacity-10 z-10 flex flex-col gap-4 items-center justify-center backdrop-filter backdrop-blur-xs backdrop-grayscale-90'
            initial={'normal'}
            animate={props.hover ? 'hover' : 'normal'}
            variants={{ 'hover': { opacity: 1, display: 'flex' }, 'normal': { opacity: 0, transitionEnd: { display: 'none' } } }} >


            {/* <div className='bg-tv-red text-sm uppercase font-bold text-white py-1 px-1.5'>Terzéváros</div> */}

            <motion.div
                // transition={transition}
                className={'bg-tv-purple text-sm text-center uppercase font-bold text-white py-1 px-1.5 ' + ((!props.title && !props.subTitle) && 'opacity-0')}
                initial={'normal'}
                animate={props.hover ? 'hover' : 'normal'}
                variants={{ 'hover': { y: 0 }, 'normal': { y: yOffset } }}>
                {props.title}
                {props.subTitle && <><br /><span className='text-xs font-thin normal-case'>{props.subTitle}</span></>}
            </motion.div>

            <motion.div
                // transition={transition}
                className='rounded-full bg-tv-gray-darkest w-14 h-14 flex items-center justify-center bg-opacity-50'
                initial={'normal'}
                animate={props.hover ? 'hover' : 'normal'}
                variants={{ 'hover': { rotateX: 0 }, 'normal': { rotateX: 90 } }}>

                <DynamicIcon className='text-xl text-white' iconName={props.iconName} />

            </motion.div>

            <motion.div
                // transition={transition}
                className={'bg-tv-purple text-xs font-semibold text-white py-1 px-1.5 ' + (!props.label && 'opacity-0')}
                initial={'normal'}
                animate={props.hover ? 'hover' : 'normal'}
                variants={{ 'hover': { y: 0 }, 'normal': { y: -yOffset } }}>
                {props.label}
            </motion.div>

        </motion.div>
    );
}