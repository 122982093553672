import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';
import DynamicIcon from '@/elements/DynamicIcon.js';

import { getWebsite } from '@/utils/WebsiteUtils.js';
import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

export default function MenuBlock(props) {
    return (
        <div className={'h-full flex flex-row items-stretch justify-center ' + (props.className || '')}>
            {getValueFromParameterMap(getWebsite().globalContent, 'menu') ? getValueFromParameterMap(getWebsite().globalContent, 'menu').itemList.map((item) => <MenuBlockMainItem key={item.id} data={item} />) : null}
        </div>
    );
}

function MenuBlockItemDropdown(props) {
    const animationOrigin = props.animationOrigin || 'y';
    return (
        <motion.div
            className={'min-w-full flex flex-col ' + (props.level % 2 !== 0 ? 'bg-tv-red ' : 'bg-tv-red-dark ') + (props.className || '')}
            initial='hidden'
            animate={props.open ? 'visible' : (props.collapse && props.enableCollapse ? 'collapse' : 'hidden')}
            variants={{
                visible: { opacity: 1, x: 0, y: 0, display: 'flex' },
                hidden: { opacity: 0, x: animationOrigin === 'y' ? 0 : 20, y: animationOrigin === 'y' ? 20 : 0, transitionEnd: { display: 'none' } },
                collapse: { opacity: 0, x: 0, y: 0, transitionEnd: { display: 'none' } }
            }}>
            {props.children}
        </motion.div>
    );
}

function MenuBlockMainItem(props) {
    const [dropdownOpen/*, setDropdownOpen*/] = useState(false);

    return (
        <div className={'relative flex group ' + (props.className || '')} /*onMouseEnter={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}*/>

            <NavLink
                to={/*'/' +*/ props.data.fullPath}
                activeClassName='bg-tv-purple dark:text-tv-gray-lightest'
                className='px-4 flex items-center text-xxs dark:text-xs text-white dark:text-black uppercase font-semibold dark:font-bold whitespace-nowrap tracking-widest transition group-hover:bg-tv-yellow dark:group-hover:bg-white group-hover:text-black'>
                {props.data.title}
            </NavLink>

            {props.data.childList ? <div className='absolute w-full bottom-0'>
                <MenuBlockItemDropdown className='absolute top-0' open={dropdownOpen} level={props.data.level}>
                    {props.data.childList.map((item, index) => (<MenuBlockSubItem key={index} data={item} collapse={!dropdownOpen} enableCollapse />))}
                </MenuBlockItemDropdown>
            </div> : null}

        </div>
    );
}

function MenuBlockSubItem(props) {
    const [hover, setHover] = useState(false);

    return (
        <div className={'relative flex first:pt-2 last:pb-2' + (props.className || '')} onMouseEnter={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }}>

            <Link className={'w-full flex px-4'} to={'/' + props.data.fullPath}>

                <motion.span
                    className='w-full py-1 flex items-center text-xxs text-white tracking-wide uppercase whitespace-nowrap'
                    initial='closed'
                    animate={props.data.childList ? (hover ? 'open' : 'closed') : (hover ? 'hover' : 'closed')}
                    variants={{ open: { opacity: 1 }, closed: { opacity: 0.75 }, hover: { opacity: 1 } }}>
                    {props.data.title}
                </motion.span>

                <motion.div
                    className='flex items-center justify-center ml-4'
                    initial='closed'
                    animate={props.data.childList ? (hover ? 'open' : 'closed') : (hover ? 'hover' : 'closed')}
                    variants={{ open: { rotate: -90, opacity: 1 }, closed: { rotate: 0, opacity: 0.3 }, hover: { opacity: 1 } }}>
                    <DynamicIcon className={'text-xxs text-white'} iconName={props.data.childList ? 'FaChevronDown' : 'FaChevronRight'} />
                </motion.div>

            </Link>
            {/* </a> */}

            {props.data.childList ? <div className='absolute right-0 h-full'>
                <MenuBlockItemDropdown className={'absolute left-0 -top-2'} level={props.data.level} open={props.data.childList ? hover : false} collapse={props.collapse} enableCollapse={props.enableCollapse} animationOrigin={'x'}>
                    {props.data.childList.map((item, index) => (<MenuBlockSubItem key={index} data={item} collapse={props.collapse} enableCollapse={props.enableCollapse} />))}
                </MenuBlockItemDropdown>
            </div> : null}

        </div>
    );
}