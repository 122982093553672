import { useState, useEffect } from 'react';

export default function useDarkMode() {
    const [theme, setTheme] = useState(localStorage.getItem('tvTheme'));
    const colorTheme = theme === 'dark' ? 'light' : 'dark';
    useEffect(() => {
        const root = window.document.documentElement;
        root.classList.remove(colorTheme);
        root.classList.add(theme)
        localStorage.setItem('tvTheme', theme);
    }, [theme, colorTheme])

    return [colorTheme, setTheme];
}