import React, { useState } from 'react';

import LightboxLoader from '@/elements/lightbox/LightboxLoader.js';

import Lightbox from 'react-image-lightbox';

export default function GalleryLightbox(props) {
    const [photoIndex, setPhotoIndex] = useState(0);

    const nextSrc = props.gallery && props.gallery[(photoIndex + 1) % props.gallery.length].downloadUrl;
    const prevSrc = props.gallery && props.gallery[(photoIndex + props.gallery.length - 1) % props.gallery.length].downloadUrl;

    return (
        <>
            {props.open && (
                /* https://www.npmjs.com/package/react-image-lightbox */
                <Lightbox
                    mainSrc={props.gallery[photoIndex].downloadUrl}
                    imageTitle={props.gallery[photoIndex].caption}
                    imageCaption={props.gallery[photoIndex].copyright}
                    nextSrc={props.gallery.length > 1 && nextSrc}
                    prevSrc={props.gallery.length > 1 && prevSrc}
                    nextSrcThumbnail={props.gallery.length > 1 && nextSrc}
                    prevSrcThumbnail={props.gallery.length > 1 && prevSrc}
                    onCloseRequest={() => { props.onClose() }}
                    loader={<LightboxLoader />}
                    closeLabel='Bezárás'
                    zoomInLabel='Nagyítás'
                    zoomOutLabel='Kicsinyítés'
                    nextLabel='Következő'
                    prevLabel='Előző'
                    imageLoadErrorMessage='Hiba a kép betöltése közben.'
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + props.gallery.length - 1) % props.gallery.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % props.gallery.length)}
                />
            )}
        </>
    );
}