import globalConfig from '../config/globalConfig.js'

export const startsWithVowel = s => {
    const vowels = ['a', 'á', 'e', 'é', 'i', 'í', 'o', 'ó', 'ö', 'ő', 'u', 'ú', 'ü', 'ű'];
    return vowels.some(v => s.toLowerCase().startsWith(v));
};

export const getVowelAdjective = s => {
    return s ? (startsWithVowel(s) ? 'az' : 'a') : 'a(z)';
};

export const getConfigDataArr = (data, config) => {
    const prefixSeparator = ': ';
    let dataArray = [];

    for (var key in data) {
        if (!config.show || config.show.indexOf(key) > -1) {
            if (data[key].url)
                dataArray.push(<div className={config.className ? config.className : null}>{config.prefix ? <span>{data[key].prefix + prefixSeparator}</span> : null} <a key={key} href={data[key].url}>{data[key].label}</a></div>);
            else
                dataArray.push(<div key={key} className={config.className ? config.className : null}>{(config.prefix ? data[key].prefix + prefixSeparator : '') + data[key].label}</div>);
        }
    }

    return dataArray.length ? dataArray : null;
};

export const getMouseEventDirection = (ev, obj, asString) => {
    const { width, height, top, left } = obj.getBoundingClientRect();
    const l = ev.pageX - (left + window.pageXOffset);
    const t = ev.pageY - (top + window.pageYOffset);
    const x = (l - (width / 2) * (width > height ? (height / width) : 1));
    const y = (t - (height / 2) * (height > width ? (width / height) : 1));
    const direction = Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4;
    if (!asString)
        return direction;
    else if (direction === 0)
        return 'top';
    else if (direction === 1)
        return 'right';
    else if (direction === 2)
        return 'bottom';
    else if (direction === 3)
        return 'left';
};

export const getFlipHover = (ref, e, show) => {
    return (show ? 'in-' : 'out-') + getMouseEventDirection(e, ref.current, true);
};

export const getDateString = (date, options) => {
    var _options = {
        // weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    if (options && options.time) {
        _options['hour'] = 'numeric';
        _options['minute'] = 'numeric';
    }

    return date.toLocaleDateString('hu-HU', _options);
};

export const getQueryVariable = (search, variable) => {
    var query = search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
}

export const getSearchUrl = (term) => {
    if (term && term !== '') return '/' + globalConfig.search.path + '?' + globalConfig.search.queryParam + '=' + term;
    else return '/' + globalConfig.search.path;
}

export const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    return array;
}