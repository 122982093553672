import React, { useState } from 'react';
import DynamicIcon from '@/elements/DynamicIcon.js';
import { motion } from 'framer-motion';
import { generateFbShareLink } from '@/utils/ShareUtils.js';

export default function ShareButton(props) {
    const [hover, setHover] = useState(false);

    let params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=540,height=300,left=100,top=100';

    return (
        <div className='relative flex flex-row justify-center items-center group cursor-pointer ' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

            <div className={'pl-2 transition group-hover:text-tv-red group-hover:font-semibold ' + (props.labelClassName || '')}>Megosztás</div>

            <div className='absolute left-0 h-full flex justify-center items-center'>
                <motion.div
                    className='absolute right-0 flex flex-row py-1 px-2 rounded-full bg-tv-red  gap-2 overflow-hidden'
                    initial='closed'
                    animate={hover ? 'open' : 'closed'}
                    variants={{ open: { opacity: 1, width: 'auto' }, closed: { opacity: 0, width: 0 } }} >
                    <ShareItem label='Facebook' icon='FaFacebookF' onClick={() => { window.open(generateFbShareLink(props.image, props.title, props.content, props.url), '_blank', params) }} />
                    <ShareItem label='Link másolása' icon='FaLink' onClick={() => { navigator.clipboard.writeText(window.location.href) }} />
                </motion.div>
            </div>

        </div>
    );
}

function ShareItem(props) {
    return (
        <button className='flex flex-row gap-1 justify-center items-center text-white hover:text-tv-yellow transition' onClick={props.onClick}>
            <DynamicIcon className={'text-xs'} iconName={props.icon} />
            <div className='text-xxs whitespace-nowrap'>{props.label}</div>
        </button>
    )
}