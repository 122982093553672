const swiperConfig = {
    'screenList': [
        {
            'hash': null,
            'pathname': '/',
            'icon': '/assets/images/icon/tv/gray-dark/2.svg',
            'activeIcon': '/assets/images/icon/tv/red/2.svg'
        },
        {
            'hash': 'hirek',
            'pathname': '/',
            'icon': '/assets/images/icon/tv/gray-dark/6.svg',
            'activeIcon': '/assets/images/icon/tv/red/6.svg'
        },
        {
            'hash': 'terkep',
            'pathname': '/',
            'icon': '/assets/images/icon/tv/gray-dark/18.svg',
            'activeIcon': '/assets/images/icon/tv/red/18.svg'
        },
        // {
        //     'hash': 'lorem',
        //     'pathname': '/',
        //     'icon': '/assets/images/icon/tv/gray-dark/5.svg',
        //     'activeIcon': '/assets/images/icon/tv/red/5.svg'
        // },
        // {
        //     'hash': 'ipsum',
        //     'pathname': '/',
        //     'icon': '/assets/images/icon/tv/gray-dark/19.svg',
        //     'activeIcon': '/assets/images/icon/tv/red/19.svg'
        // }
    ]
}

export default swiperConfig;