import React from 'react';
import Sticky from 'react-stickynode';

import ZoomImage from '@/elements/lightbox/ZoomImage.js';
import DynamicIcon from '@/elements/DynamicIcon.js';

import globalConfig from '@/config/globalConfig.js'
import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

export default function IframeContent(props) {
    const _data = {
        list: getValueFromParameterMap(props.data.value, 'iframe-content') && getValueFromParameterMap(props.data.value, 'iframe-content').contentList,
    };

    console.log(_data.list)

    return (
        <div className={'w-full flex flex-col p-4 md:p-8 gap-4 md:gap-8 ' + (props.className || '')}>

            {_data.list.map((item) => (<Item key={item.id} data={item} />))}

        </div >
    );
}


function Item(props) {
    const _data = {
        url: getValueFromParameterMap(props.data, 'url'),
    };

    return (
        <div className='flex flex-row items-center border-8 border-tv-gray-light'>
            <iframe className='w-full' style={{ height: '1090px' }} src={_data.url} />
        </div>
    )
}