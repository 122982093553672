import Sticky from 'react-stickynode';

import globalConfig from '@/config/globalConfig.js'

export default function StickyHeader(props) {
    return (
        <Sticky top={globalConfig.etc.headerStickyOffset} className='z-10' enabled={!props.disabled}>
            <div className='flex flex-row bg-tv-red text-white overflow-hidden bg-no-repeat bg-bottom sm:bg-top bg-cover' style={{ backgroundImage: 'url(/assets/images/pattern-red.png)' }}  >

                <div className='flex-1 flex flex-col px-4 py-6 sm:p-6 gap-1 sm:gap-0.5 justify-between'>

                    <div className='pb-4 md:pb-0 gap-2'>

                        {props.heading && <h1 className='text-3xl sm:text-4xl uppercase font-bold' >{props.heading}</h1>}
                        {props.htmlHeading && <h1 className='text-3xl sm:text-4xl uppercase font-bold' dangerouslySetInnerHTML={{ __html: props.htmlHeading }} />}

                        {props.subHeading && <h2 className='text-lg sm:text-xl font-bold' >{props.subHeading}</h2>}
                        {props.htmlSubHeading && <h2 className='text-lg sm:text-xl font-bold' dangerouslySetInnerHTML={{ __html: props.htmlSubHeading }} />}

                        {props.content && <h2 className={'mt-2 content ckecontent ckecontent-sticky ' + (props.small ? 'text-xs dark:text-sm sm:text-sm sm:dark:text-base leading-snug' : 'text-xs dark:text-sm sm:text-base sm:dark:text-lg')} dangerouslySetInnerHTML={{ __html: props.content }} />}

                    </div>

                </div>

            </div>
        </Sticky>
    )
}