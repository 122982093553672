import globalConfig from '../config/globalConfig.js'

export const generateFbShareLink = (image, title, content, url) => {
    // let _url = 'https://www.facebook.com/dialog/feed?app_id=1389892087910588%20&redirect_uri=https://scotch.io%20&link=https://scotch.io%20&picture=http://placekitten.com/500/500%20&caption=This%20is%20the%20caption%20&description=This%20is%20the%20description&display=popup'
    let _url = 'https://www.facebook.com/dialog/feed?app_id=' + globalConfig.etc.facebookAppId + '%20&redirect_uri=' + url + '%20&link=' + url + '%20&picture=' + (image || globalConfig.etc.ogDefaultImage) + '%20&caption=' + title + '%20&description=' + content;
    // let _url = 'https://www.facebook.com/dialog/feed?app_id=' + globalConfig.etc.facebookAppId + '%20&redirect_uri=' + url + '%20&link=' + image + '%20&caption=' + title + '%20&description=' + content
    return _url;
};

export const hasNativeShare = () => {
    if (navigator.share)
        return true;
    else
        return false;
};

export const shareNative = (title, content, url) => {
    if (navigator.share) {
        navigator.share({
            title: title,
            text: content,
            url: url
        });
    } else {
        console.log("Navigator can't share.")
    }
};