import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import StickyHeader from '@/elements/StickyHeader';
import InputCard from '@/elements/input/InputCard.js';

import FormFrontendControl from '@/services/FormFrontendControl.js';

export default function Form(props) {
    const [questionIndex, setQuestionIndex] = useState(0);
    const [form, setForm] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [screen, setScreen] = useState('FORM');

    const history = useHistory();

    useEffect(() => {
        if (props.data && props.data.value) FormFrontendControl.getForm(props.data.value, 0).then(result => { setForm(result) });
    }, [props.data]);

    // useEffect(() => {
    // console.log(answers);
    // }, [answers]);

    const handleAnswerChange = (key, answer) => {
        let _answers = [];
        for (let i = 0; i < answers.length; i++) _answers[i] = answers[i];
        let hasItem = false;
        for (let i = 0; i < _answers.length; i++) { if (_answers[i].key === key) { _answers[i].answer = answer; hasItem = true; } }
        if (!hasItem) _answers.push({ key: key, answer: answer });
        setAnswers(_answers);
    };

    const getAnswer = (key) => {
        let answer = null; for (let i = 0; i < answers.length; i++) if (answers[i].key === key) answer = answers[i].answer;
        return answer;
    };

    const getQuestionByKey = (key) => {
        for (let i = 0; i < form.questionList.length; i++)
            if (form.questionList[i].key === key)
                return form.questionList[i];
        return null;
    };

    const getAnswerItemByKey = (question, key) => {
        for (let i = 0; i < question.answerList.length; i++)
            if (question.answerList[i].key === key)
                return question.answerList[i];
        return null;
    };

    const getAnswerByKey = (key) => {
        for (let i = 0; i < answers.length; i++)
            if (answers[i].key === key)
                return answers[i].answer;
        return null;
    };

    const getFormatedAnswer = (key) => {
        let answer = null;
        if (getQuestionByKey(key).questionType !== 'SELECT_ONE' && getQuestionByKey(key).questionType !== 'SELECT_ANY') {
            answer = getAnswerByKey(key);
        } else {
            let answerItem = getAnswerItemByKey(getQuestionByKey(key), getAnswerByKey(key));
            if (answerItem != null)
                answer = answerItem.title;
        }
        return answer
    };

    const isFinished = () => {
        for (let i = 0; i < form.questionList.length; i++)
            if (!getAnswerByKey(form.questionList[i].key))
                return false;
        return true;
    };

    const send = () => {
        let _result = {};
        _result.questionIndex = questionIndex;
        _result.surveyKey = form.key;

        let answerMap = {};
        for (let i = 0; i < answers.length; i++)
            answerMap[answers[i].key] = answers[i].answer;
        _result.answers = answerMap;

        FormFrontendControl.submit(_result, 0).then(result => {
            if (result && result.successful)
                setScreen('FEEDBACK');
        });
    };

    const setNextQuestion = () => { if (questionIndex < form.questionList.length - 1) setQuestionIndex(questionIndex + 1) };
    const setPrevQuestion = () => { if (questionIndex > 0) setQuestionIndex(questionIndex - 1) };

    const getInputComponent = () => {
        const question = form.questionList[questionIndex];
        const questionType = question.questionType;
        const answer = getAnswer(question.key);

        if (question.content && question.content.parameterMap.cardType && question.content.parameterMap.kartyak) {

            return <CardInput
                cards={question.content.parameterMap.kartyak.value.contentList}
                cardType={question.content.parameterMap.cardType.value}
                inputLength={question.maxChar}
                answer={answer}
                onAnswerChanged={answer => handleAnswerChange(form.questionList[questionIndex].key, answer)} />;

        } else if (questionType === 'SELECT_ONE' || questionType === 'SELECT_ANY') {

            return <SelectInput
                optionList={form.questionList[questionIndex].answerList}
                multiple={questionType === 'SELECT_ANY'}
                answer={answer}
                onAnswerChanged={answer => handleAnswerChange(form.questionList[questionIndex].key, answer)} />;

        } else if (questionType === 'STRING' || questionType === 'TEXT') {

            return <TextInput
                multiline={questionType === 'TEXT'}
                answer={answer}
                inputLength={question.maxChar}
                onAnswerChanged={answer => handleAnswerChange(form.questionList[questionIndex].key, answer)} />;

        } else if (questionType === 'DROPDOWN') {

            return <DropdownInput
                optionList={form.questionList[questionIndex].answerList}
                answer={answer}
                onAnswerChanged={answer => handleAnswerChange(form.questionList[questionIndex].key, answer)} />;

        } else {
            return <div className='text-sm font-semibold text-tv-gray-dark text-center py-4'>Hiba a kérdés megjelenítése során.</div>
        }
    }

    return (
        <div className={'w-full flex flex-col min-h-full ' + (props.className || '')}>

            {(form && form.questionList[questionIndex].longPublicDescription) && < StickyHeader content={form.questionList[questionIndex].longPublicDescription} />}

            {form && <div className='w-full flex-1 flex flex-col space-y-6 justify-between p-4 sm:p-6'>

                {screen === 'FORM' && <div className='w-full flex flex-col justify-center space-y-6 flex-1'>
                    <div className='dark:text-white text-centere flex flex-col space-y-2 text-center'>
                        <div className='text-3xl sm:text-4xl font-bold'>{(questionIndex + 1) + '. ' + form.questionList[questionIndex].title}</div>
                        {form.questionList[questionIndex].publicDescription && <div className='text-base '>{form.questionList[questionIndex].publicDescription}</div>}
                    </div>
                    {getInputComponent(form.questionList[questionIndex].questionType)}
                </div>}

                {screen === 'CHECK' && <div className='w-full flex flex-col justify-center space-y-6 flex-1'>
                    <div className='dark:text-white text-centere flex flex-col space-y-2 text-center'>

                        {(form && form.checkTitle) && <div className='text-3xl sm:text-4xl font-bold'>{form.checkTitle}</div>}
                        {(form && form.checkMessage) && <section className='text-base' dangerouslySetInnerHTML={{ __html: form.checkMessage }} />}

                        <div className='flex flex-col space-y-2 pt-2'>
                            {form.questionList.map((item, index) => <SummaryItem
                                key={index}
                                index={index}
                                question={item.title}
                                answer={getFormatedAnswer(item.key)} />)}
                        </div>

                        {!isFinished() && <div className='rounded bg-tv-red p-2 text-white font-bold'>Kérjük, ellenőrizze a kérdéseket, és adjon választ az összes kérdésre!</div>}

                    </div>
                </div>}

                {screen === 'FEEDBACK' && <div className='w-full flex flex-col justify-center space-y-6 flex-1'>
                    <div className='dark:text-white text-centere flex flex-col space-y-2 text-center'>

                        {(form && form.feedbackTitle) && <div className='text-3xl sm:text-4xl font-bold'>{form.feedbackTitle}</div>}
                        {(form && form.feedbackMessage) && <section className='text-base' dangerouslySetInnerHTML={{ __html: form.feedbackMessage }} />}

                    </div>
                </div>}

                <BottomBar
                    screen={screen}
                    questionsLength={form.questionList.length}
                    questionIndex={questionIndex}
                    onNext={() => setNextQuestion()}
                    onPrev={() => setPrevQuestion()}
                    onFormFinished={() => setScreen('CHECK')}
                    onChecked={() => send()}
                    onFinished={() => history.push('/')}
                    onBackToForm={() => setScreen('FORM')}
                    nextDisabled={(screen === 'CHECK' && !isFinished()) ? true : false}
                    prevDisabled={questionIndex === 0} />

            </div>}

        </div >
    );
}

function SummaryItem(props) {
    return (
        <div className='w-full flex flex-col sm:flex-row items-center gap-2 sm:gap-4 rounded odd:bg-tv-gray-light even:bg-tv-gray-lightest p-2'>
            <div className='flex-1 font-bold text-center sm:text-right'>{props.question}</div>
            <div className='flex-1 text-center sm:text-left'>{props.answer ? props.answer : 'Nem megadott'}</div>
        </div>
    );
}

function BottomBar(props) {
    // const first = props.questionIndex === 0;
    const last = props.questionIndex === props.questionsLength - 1;

    const getLeftButtonLabel = () => {
        if (props.screen === 'CHECK')
            return 'Vissza a kérdésekre';
        if (props.screen === 'FORM')
            return 'Előző kérdés';
        else
            return 'Vissza';
    };

    const onLeftClick = () => {
        if (props.screen === 'FEEDBACK')
            props.onClose();
        else if (props.screen === 'CHECK')
            props.onBackToForm();
        else
            props.onPrev();
    };

    const getRightButtonLabel = () => {
        if (props.screen === 'FEEDBACK')
            return 'Vissza a főoldalra';
        if (props.screen === 'CHECK')
            return 'Adatok beküldése';
        else if (last)
            return 'Adatok ellenőrzése';

        return 'Következő kérdés';
    };

    const onRightClick = () => {
        if (props.screen === 'FEEDBACK')
            props.onFinished();
        else if (props.screen === 'CHECK')
            props.onChecked();
        else if (last)
            props.onFormFinished();
        else
            props.onNext();
    };

    const getBottomInfo = () => {
        return props.screen === 'FORM' ? ((props.questionIndex + 1) + '/' + props.questionsLength + '. kérdés') : 'Adatok ellenőrzése';
    };

    return (
        <div className='w-full flex flex-col sm:flex-row space-x-0 sm:space-x-2 justify-between items-stretch sm:items-center'>

            {(props.screen !== 'FEEDBACK') && <>

                <button className='btn mt-2 sm:mt-0 sm:flex-1 sm:max-w-64 order-3 sm:order-1' onClick={() => onLeftClick()} disabled={props.prevDisabled}>{getLeftButtonLabel()}</button>

                <div className='sm:flex-1 order-2 sm:order-2 bg-tv-gray-light sm:bg-transparent rounded p-2'>
                    <div className='text-tv-gray-dark dark:text-white text-xs sm:text-sm text-center'>{getBottomInfo()}</div>
                </div>

            </>}

            <button className={'btn mt-2 sm:mt-0 sm:flex-1 order-2 sm:order-3 ' + (props.screen !== 'FEEDBACK' ? 'sm:max-w-64' : 'w-full')} onClick={() => onRightClick()} disabled={props.nextDisabled}>{getRightButtonLabel()}</button>

        </div>
    );
}


function SelectInput(props) {
    const handleAnswer = (key) => {
        if (!isSelected(key)) {
            if (props.multiple) { let arr = answerToArr(props.answer); arr.push(key); props.onAnswerChanged(answerToStr(arr)) } else { props.onAnswerChanged(key) }
        } else {
            let arr = answerToArr(props.answer); let index = arr.indexOf(key); arr.splice(index, 1);
            props.onAnswerChanged(answerToStr(arr))
        }
    };

    const answerToStr = (arr) => { let str = ''; for (var i = 0; i < arr.length; i++)str += (i === 0 ? '' : ',') + arr[i]; return str === '' ? null : str; };
    const answerToArr = (str) => { return str !== null ? str.split(',') : []; };
    const isSelected = (key) => { return answerToArr(props.answer).indexOf(key) > -1; }

    return (
        <div className='w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2'>
            {props.optionList.map((item, index) => <OptionItem key={index} option={item} selected={isSelected(item.key)} onSelectChanged={key => handleAnswer(key)} />)}
        </div>
    );
}

function OptionItem(props) {
    const className = 'transition p-6 text-left border m-2';
    const normalClassName = 'hover:bg-tv-gray-lightest';
    const selectedClassName = 'bg-tv-purple hover:bg-tv-yellow text-white hover:text-tv-gray-darkest shadow';

    return (
        <button className={className + ' ' + (props.selected ? selectedClassName : normalClassName)} onClick={() => props.onSelectChanged(props.option.key)}>
            {props.option.title}
        </button>
    );
}

const _inputClassName = 'w-full border-2 border-tv-gray-dark focus:border-tv-red transition rounded p-4 outline-none';
const genericInputClassName = _inputClassName;
const genericAreaClassName = _inputClassName + ' h-56 resize-none';
const genericPlaceholder = 'Kérjük, írja ide válaszát ...';

function TextInput(props) {
    return (
        <div className='relative w-full flex flex-row items-center justify-center'>

            {props.multiline ?
                <textarea
                    placeholder={genericPlaceholder}
                    className={genericAreaClassName}
                    value={props.answer || ''}
                    maxLength={props.inputLength}
                    onChange={e => props.onAnswerChanged(e.target.value !== '' ? e.target.value : null)} />
                : <input
                    placeholder={genericPlaceholder}
                    className={genericInputClassName}
                    value={props.answer || ''}
                    maxLength={props.inputLength}
                    onChange={e => props.onAnswerChanged(e.target.value !== '' ? e.target.value : null)} />}

            {props.inputLength && <InputCounter count={(props.answer ? props.answer.length : 0)} max={props.inputLength} />}

        </div>
    );
}

function DropdownInput(props) {
    return (
        <div className='w-full flex flex-row'>
            <select
                className={genericInputClassName}
                value={props.answer || ''}
                onChange={e => props.onAnswerChanged(e.target.value !== '' ? e.target.value : null)}>

                <option value={''}>Kérjük válasszon ...</option>
                {props.optionList.map((item, index) => <option key={index} value={item.key}>{item.title}</option>)}

            </select>
        </div>
    );
}

function CardInput(props) {
    const multipleSeparator = ' / ';

    const getItemByAnswer = (answer) => {
        for (let i = 0; i < props.cards.length; i++)
            if (props.cards[i].parameterMap['helyettesito-szoveg'].value === answer)
                return props.cards[i];
        return null;
    };

    const answerToArray = (answerString) => {
        return answerString ? answerString.split(multipleSeparator) : [];
    };

    const answerToString = (answerArray) => {
        let string = '';
        for (let i = 0; i < answerArray.length; i++)
            string += (i ? multipleSeparator : '') + answerArray[i];
        return string === '' ? null : string;
    };

    const handleAnswer = (answer) => {
        if (props.cardType === 'MultiSelect') {

            let currentArray = answerToArray(props.answer);
            const indexOfAnswer = currentArray.indexOf(answer);

            if (indexOfAnswer < 0)
                currentArray.push(answer);
            else
                currentArray.splice(indexOfAnswer, 1);

            props.onAnswerChanged(answerToString(currentArray));

        } else {
            if (props.answer !== answer)
                props.onAnswerChanged(answer);
            else
                props.onAnswerChanged(null);
        }
    };

    const isInputVisible = () => {
        let is = false;

        if (props.cardType === 'Info')
            is = true;
        else if (props.cardType !== 'MultiSelect' && props.answer && !answerExist(props.answer))
            is = true;

        return is;
    };

    const isArea = () => {
        return props.inputLength > 300;
    };

    const isSelected = (answer) => {
        let is = false;

        if (props.cardType === 'MultiSelect') {

            let currentArray = answerToArray(props.answer);
            const indexOfAnswer = currentArray.indexOf(answer);
            if (indexOfAnswer >= 0)
                is = true;

        } else if (props.cardType === 'Info') {

            is = true;

        } else {

            if (props.answer && !answerExist(props.answer)) {

                let item = getItemByAnswer(answer);
                if (item.parameterMap['egyeb-valasz-kulon-szoveges-input'].value)
                    is = true;

            } else if (props.answer === answer) {

                is = true;

            }

        }

        return is;
    };

    const answerExist = (answer) => {
        let exists = false;
        for (let i = 0; i < props.cards.length; i++)
            if (props.cards[i].parameterMap['helyettesito-szoveg'].value === answer && !props.cards[i].parameterMap['egyeb-valasz-kulon-szoveges-input'].value)
                exists = true;
        return exists;
    };

    return (
        <div className='w-full flex flex-col space-y-8'>

            <div className='flex flex-row flex-wrap items-center justify-center '>

                {props.cards.map((item, index) => <InputCard
                    className='m-4'
                    key={index}
                    data={item}
                    selectable={props.cardType === 'Info' ? false : true}
                    selected={isSelected(item.parameterMap['helyettesito-szoveg'].value)}
                    blur={props.answer != null && !isSelected(item.parameterMap['helyettesito-szoveg'].value)}
                    onClick={() => (props.cardType !== 'Info') && handleAnswer(item.parameterMap['helyettesito-szoveg'].value)} />
                )}

            </div>

            <div className={'relative ' + (isInputVisible() ? 'block' : 'hidden')}>

                {!isArea() && <input
                    className={genericInputClassName}
                    placeholder={genericPlaceholder}
                    value={props.answer || ''}
                    maxLength={props.inputLength}
                    onChange={e => handleAnswer(e.target.value !== '' ? e.target.value : null)} />}

                {isArea() && <textarea
                    className={genericAreaClassName}
                    placeholder={genericPlaceholder}
                    value={props.answer || ''}
                    maxLength={props.inputLength}
                    onChange={e => handleAnswer(e.target.value !== '' ? e.target.value : null)} />}

                {props.inputLength && <InputCounter count={(props.answer ? props.answer.length : 0)} max={props.inputLength} />}

            </div>

        </div>
    );
}

function InputCounter(props) {
    return (<div className='absolute bg-tv-red p-1 text-white text-xxs rounded -bottom-1 -right-1'>{props.count + ' / ' + props.max}</div>)
}