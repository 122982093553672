import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';

import { OverlayFlags } from '@/components/tv2030/Tv2030Modal.js';
import { queryParam } from '@/components/tv2030/Tv2030Map.js';

import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

import MapControl from '@/services/MapControl.js';

export default function Tv2030List(props) {
    const [itemList, setItemList] = useState(null);
    const elementRef = useRef(null);

    let location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (location.pathname.startsWith('/terezvaros-2030/') && itemList != null) window.scrollTo(0, elementRef.current.getBoundingClientRect().y);
    }, [location, itemList]);

    const _setItemId = (id) => {
        let path = null;
        if (id && id !== '') path = location.pathname + '?' + queryParam + '=' + id;
        else path = location.pathname;
        history.push(path);
    }

    // useEffect(() => {
    //     MapControl.getProjectList().then(result => {
    //         result.sort((a, b) => (getValueFromParameterMap(a.content, 'title') > getValueFromParameterMap(b.content, 'title')) ? 1 : ((getValueFromParameterMap(b.content, 'title') > getValueFromParameterMap(a.content, 'title')) ? -1 : 0))
    //         setItemList(result)
    //     });
    // }, [])

    useEffect(() => {
        if (props.initialData) {
            let result = props.initialData;
            result.sort((a, b) => (getValueFromParameterMap(a.content, 'title') > getValueFromParameterMap(b.content, 'title')) ? 1 : ((getValueFromParameterMap(b.content, 'title') > getValueFromParameterMap(a.content, 'title')) ? -1 : 0))
            setItemList(result);
        }
    }, [props.initialData]);

    let _data = {
        staticContent: getValueFromParameterMap(props.data, 'static-content'),
        type: getValueFromParameterMap(props.data, 'type'),
    }

    const shouldBeVisible = (item, type) => {
        const itemTypeKey = getValueFromParameterMap(item.content, 'type') && getValueFromParameterMap(getValueFromParameterMap(item.content, 'type'), 'key');
        if (!type || type.length === 0) return false;
        let list = [];
        for (let i = 0; i < type.length; i++) list.push(getValueFromParameterMap(type[i], 'key'));
        return list.indexOf(itemTypeKey) > -1;
    };

    return (
        <div className={'w-full flex flex-col ' + (props.className || '')} ref={elementRef}>

            <Content data={_data.staticContent} />

            <div className='w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-8 pt-0 sm:pt-0'>

                {itemList && itemList.map((item) => shouldBeVisible(item, _data.type) && <GridItem key={item.content.id} data={item} onClick={() => _setItemId(item.content.id)} />)}

            </div>

            {/* {itemKey && <Tv2030Modal itemKey={itemKey} onClose={() => setItemKey(false)} />} */}

        </div>
    )
}

function Content(props) {
    let _data = {
        title: getValueFromParameterMap(props.data, 'heading'),
        subTitle: getValueFromParameterMap(props.data, 'subHeading'),
        lead: getValueFromParameterMap(props.data, 'lead'),
        content: getValueFromParameterMap(props.data, 'content'),
        image: getValueFromParameterMap(props.data, 'coverImage'),
    }

    return (
        <div className='content ckecontent'>

            {(_data.title || _data.subTitle) && <div className='bg-tv-red text-white p-4 sm:p-8 bg-no-repeat bg-center bg-cover' style={{ backgroundImage: 'url(/assets/images/pattern-red.png)' }}>
                {_data.title && <h1>{_data.title}</h1>}
                {_data.subTitle && <h2>{_data.subTitle}</h2>}
            </div>}

            <div className='p-4 sm:p-8'>
                {_data.image && <div className='w-full bg-center bg-no-repeat bg-cover bg-tv-gray-lighter bg-opacity-50' style={{ backgroundImage: 'url(' + _data.image.downloadUrl + ')' }} >
                    <svg className='invisible' viewBox='0 0 16 9' />
                </div>}

                {_data.lead && <section className='lead' dangerouslySetInnerHTML={{ __html: _data.lead }} />}
                {_data.content && <section dangerouslySetInnerHTML={{ __html: _data.content }} />}
            </div>

        </div>
    );
}

function GridItem(props) {
    const [hover, setHover] = useState(false);

    const _data = {
        title: getValueFromParameterMap(props.data['content'], 'title'),
        subTitle: getValueFromParameterMap(props.data['content'], 'sub-title'),
        type: getValueFromParameterMap(getValueFromParameterMap(props.data['content'], 'type'), 'title'),
        coverImage: getValueFromParameterMap(props.data['content'], 'cover-image') && getValueFromParameterMap(props.data['content'], 'cover-image').downloadUrl,
    }

    return (
        <button className='relative overflow-hidden' onClick={props.onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

            <svg className='invisible' viewBox='0 0 1 1' />

            <div className={'absolute -inset-0 w-full h-full bg-tv-gray-darker flex items-center justify-center p-6'} style={{ perspective: 1500 }}>

                <div className='absolute w-full h-full overflow-hidden'>
                    <motion.div
                        className='absolute w-full h-full bg-center bg-no-repeat bg-cover filter grayscale opacity-10' style={{ backgroundImage: 'url(' + _data.coverImage + ')' }}
                        transition={{ ease: 'easeOut', duration: .3 }}
                        initial={'normal'}
                        animate={hover ? 'hover' : 'normal'}
                        variants={{ 'hover': { scale: 1.2 }, 'normal': { scale: 1 } }} />
                    <OverlayFlags className='left-6 bg-tv-red' />
                </div>

                <div className='w-full flex flex-col text-white text-left z-10'>
                    <div className='text-xs uppercase'>{_data.type}</div>
                    <div className={'leading-tight font-semibold ' + (_data.title.length > 16 ? 'text-xl' : 'text-2xl')}>{_data.title}</div>
                    {_data.subTitle && <div className='font-light text-xs'>{_data.subTitle}</div>}
                </div>

            </div>

        </button>
    );
}