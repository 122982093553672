import React from 'react';
import Sticky from 'react-stickynode';

import ZoomImage from '@/elements/lightbox/ZoomImage.js';
import DynamicIcon from '@/elements/DynamicIcon.js';

import globalConfig from '@/config/globalConfig.js'
import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

export default function ProfileCard(props) {
    const _data = {
        image: getValueFromParameterMap(props.data.value, 'image'),
        name: getValueFromParameterMap(props.data.value, 'name'),
        shortDescription: getValueFromParameterMap(props.data.value, 'shortDescription'),
        content: getValueFromParameterMap(props.data.value, 'content'),
        declarationOfAssets: getValueFromParameterMap(props.data.value, 'declarationOfAssets'),
        email: getValueFromParameterMap(props.data.value, 'email'),
        phone: getValueFromParameterMap(props.data.value, 'phone'),
        publicHours: getValueFromParameterMap(props.data.value, 'publicHours'),
        precinct: getValueFromParameterMap(props.data.value, 'precinct'),
        precinctImageA: getValueFromParameterMap(props.data.value, 'precinct-image-a'),
        precinctImageB: getValueFromParameterMap(props.data.value, 'precinct-image-b'),
    };

    return (
        <div className={'w-full flex flex-col ' + (props.className || '')}>

            <Sticky top={globalConfig.etc.headerStickyOffset}>
                <div className='flex flex-row bg-tv-red text-white overflow-hidden bg-no-repeat bg-bottom sm:bg-top bg-cover' style={{ backgroundImage: 'url(/assets/images/pattern-red.png)' }}  >

                    <div className='flex-1 flex flex-col px-4 py-6 sm:p-6 gap-1 sm:gap-0.5 justify-between'>

                        <div className='pb-4 md:pb-0'>

                            <h1 className='text-3xl sm:text-4xl uppercase font-bold'>{_data.name}</h1>
                            <h2 className='text-xs dark:text-sm sm:text-base sm:dark:text-lg font-semibold' dangerouslySetInnerHTML={{ __html: _data.shortDescription }} />

                        </div>

                        <div className='flex flex-col gap-2'>

                            <div className='flex flex-col gap-2'>
                                {_data.precinct && <DataLine icon='FaMap' label={_data.precinct} />}
                                {_data.phone && <DataLine icon='FaPhone' label={_data.phone} href={'tel:' + _data.phone} />}
                                {_data.email && <DataLine icon='FaAt' label={_data.email} href={'mailto:' + _data.email} />}
                                {_data.publicHours && <DataLine icon='FaClock' html={_data.publicHours} />}
                            </div>

                            {_data.declarationOfAssets && <div className='flex flex-row items-center text-sm dark:text-base'>
                                <div>Vagyonnyilatkozat:&nbsp;</div>
                                <div className='font-bold' dangerouslySetInnerHTML={{ __html: _data.declarationOfAssets }} />
                            </div>}

                        </div>

                    </div>

                    {_data.image && <ZoomImage className='w-72 h-72 hidden md:block shadow-xl bg-tv-red-dark' image={_data.image} title={_data.name} />}

                </div>
            </Sticky>

            <div className='flex flex-col gap-4 sm:gap-8 p-4 sm:p-8 bg-fill-base z-10'>
                {_data.image && <ZoomImage asImg className='w-full md:hidden shadow-xl border-8 bg-tv-gray-lighter' imageClassName='w-full' image={_data.image} title={_data.name} />}
                <section className='content ckecontent' dangerouslySetInnerHTML={{ __html: _data.content }} />
                {_data.precinctImageA && <ZoomImage className='w-full py-4 sm:py-0' asImg imageClassName='filter grayscale' alt={_data.precinct} title={_data.precinct} image={_data.precinctImageA} zoomImage={_data.precinctImageB ? _data.precinctImageB : _data.precinctImageA} />}
            </div>

        </div >
    );
}


function DataLine(props) {
    return (
        <div className='flex flex-row items-center text-xs dark:text-sm'>
            <DynamicIcon className='text-sm dark:text-base mr-2' iconName={props.icon} />
            {props.html && <div dangerouslySetInnerHTML={{ __html: props.html }} />}
            {(props.href && props.label) && <a href={props.href}>{props.label}</a>}
            {(!props.href && props.label) && <a href={props.href}>{props.label}</a>}
        </div>
    )
}