import React from 'react';

import { getTranslation } from '@/utils/DictionaryUtils.js';

export default function DictionaryText(props) {
    const getText = () => {
        return getTranslation(props.textKey, props.default);
    };

    return (<>{getText()}</>);
}