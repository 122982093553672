import React, { useEffect, useState } from 'react';

import { NBossCMSPage } from '@/cms/NBossCMS.js';

import LeftMenu from '@/components/leftmenu/LeftMenu.js';
import Content from '@/components/content/Content.js';
import ProfileCard from '@/components/profilecard/ProfileCard.js';
import CorporealMaterials from '@/components/corporealmaterials/CorporealMaterials.js';
import NewsLister from '@/components/news/NewsLister.js';
import PublicationLister from '@/components/publication/PublicationLister.js';
import AdministrationLister from '@/components/administration/AdministrationLister.js';
import Search from '@/components/search/Search.js';
import TransportedVehicles from '@/components/transportedvehicles/TransportedVehicles.js';
import FormOld from '@/components/form/FormOld.js';
import FormNew from '@/components/form/FormNew.js';
import FormDescription from '@/components/formdescription/FormDescription.js';
import Vote from '@/components/vote/Vote.js';
import ModalDialog from '@/elements/modal/ModalDialog';
import IframeContent from '@/components/iframe/IframeContent.js';
import Tv2030Map from '@/components/tv2030/Tv2030Map.js';
import Tv2030List from '@/components/tv2030/Tv2030List.js';

import MapControl from '@/services/MapControl.js';

export default function SimplePage(props) {
    const [page, setPage] = useState(null);

    const [modalData, setModalData] = useState(null);
    const [modalVisible, setModalVisible] = useState(null);

    const [tv2030Data, setTv2030Data] = useState(null);

    useEffect(() => {
        if (page && page.parameterMap && page.parameterMap.content && page.parameterMap.content.value && page.parameterMap.content.value.parameterMap.popup && page.parameterMap.content.value.parameterMap.popup.value)
            setModalData(page.parameterMap.content.value.parameterMap.popup)
        else
            setModalData(null)

        if (page && page.pageTemplate.key === 'terezvaros-2030') MapControl.getProjectList().then(result => { setTv2030Data(result) });
    }, [page])

    useEffect(() => {
        if (modalData)
            setTimeout(() => setModalVisible(true), 1500);
    }, [modalData])

    const getContentComponent = (content) => {
        if (page.pageTemplate.key === 'keresesi-talalatok') {
            return <Search />;
        } else if (page.pageTemplate.key === 'form') {
            if (page.parameterMap.type && page.parameterMap.type.value === 'NEW')
                return <FormNew data={page.parameterMap['form']} />;
            else
                return <FormOld data={page.parameterMap['form']} />;
        } else if (page.parameterMap['yearsList']) {
            return <PublicationLister contentData={content} listData={page.parameterMap['yearsList']} />;
        } else if (page.parameterMap['contentList'] && page.parameterMap['contentList'].value && page.parameterMap['contentList'].value.key === 'contentType(tervek-es-koncepciok)') {
            return <PublicationLister contentData={content} /*listData={page.parameterMap['yearsList']} */ data={page.parameterMap['contentList'].value} />;
        } else if (page.parameterMap['hir-lista-hirek']) {
            return <NewsLister data={page.parameterMap['hir-lista-hirek']} />;
        } else if (page.parameterMap['ugyintezes-lista-ugyintezesek']) {
            return <AdministrationLister data={page.parameterMap['ugyintezes-lista-ugyintezesek']} />;
        } else if (content && content.value) {
            if (content.value.contentType === 'static-content' || content.value.contentType === 'news')
                return <Content data={content} />;
            else if (content.value.contentType === 'testuleti-anyagok' || content.value.contentType === 'bizottsagi-anyagok' || content.value.contentType === 'nemzetisegi')
                return <CorporealMaterials data={content} />;
            else if (content.value.contentType === 'kepviseloi-adatlap')
                return <ProfileCard data={content} />;
            else if (content.value.contentType === 'iframe-content')
                return <IframeContent data={content} />;
        } else if (page.pageTemplate.key === 'elszallitott-jarmuvek-listaja') {
            return <TransportedVehicles data={page.parameterMap['jarmuvek-lista']} />;
        } else if (page.pageTemplate.key === 'form-description') {
            return <FormDescription data={page} />;
        } else if (page.pageTemplate.key === 'zold-terezvarosert-szavazas') {
            return <Vote data={page} />;
        } else if (page.pageTemplate.key === 'terezvaros-2030') {
            return <Tv2030List data={page} initialData={tv2030Data} />;
        } else { return null }
    }

    return (
        <>

            {(page && page.pageTemplate.key === 'terezvaros-2030') && <Tv2030Map initialData={tv2030Data} />}

            <NBossCMSPage className='flex-1 flex flex-col' pageprops={props} pageDataReady={page => { setPage(page) }} >

                <div className='w-full flex-1 flex flex-col items-center' >

                    <div className='w-full flex-1 max-w-tv-boxed flex flex-col sm:flex-row' >

                        {props.templateProps.menu && <LeftMenu className={'flex-shrink-0 self-stretch ' + ((page && page.parameterMap.menu && page.parameterMap.menu.value) ? 'flex' : 'hidden sm:flex')} data={page && page.parameterMap.menu} sticky={page && page.pageTemplate.key === 'terezvaros-2030'} />}

                        {page && <div className='w-full bg-fill-base shadow-boxed-content'>{getContentComponent(page.parameterMap.content)}</div>}

                    </div>

                </div>

                {(modalData && modalVisible) && <ModalDialog
                    title={modalData.value.parameterMap['cim'].value}
                    content={modalData.value.parameterMap['szoveg'].value}
                    primaryButtonLabel={modalData.value.parameterMap['bezaras-gomb-felirata'].value}
                    onPrimaryButtonClick={() => { setModalVisible(false) }}
                    onClose={() => { setModalVisible(false) }} />}

            </NBossCMSPage>

        </>
    );
}