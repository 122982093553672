
import { useEffect } from 'react';
import 'react-image-lightbox/style.css';

import NBossCMSClient from '@/cms/NBossCMS.js';

import nBossConnection from '@/services/nBossConnection.js';

function App() {
  useEffect(() => {
    nBossConnection.setErrorHandler(error => {
      // if (typeof error.response.status !== 'undefined' && error.response.status === 500) alert('Internal Server Error!');  else 
      alert(error);
    });
  });

  return (<NBossCMSClient domainName='terezvaros.hu' />);
}

export default App;
