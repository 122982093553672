import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import DynamicIcon from '@/elements/DynamicIcon.js';
import DictionaryText from '@/elements/DictionaryText.js';

import { getWebsite } from '@/utils/WebsiteUtils.js';

import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

export default function InfoBlock(props) {
    const importantMessage = getValueFromParameterMap(getWebsite().globalContent, 'importantMessage');

    return (
        <div className={'w-full flex flex-row justify-center sm:justify-start ' + (props.className || '')}>

            <div className='w-full flex flex-row gap-2'>
                {(importantMessage && importantMessage.contentList.length > 0) && <>

                    <div className='hidden sm:flex h-full bg-tv-purple dark:bg-white px-3 items-center justify-center'>
                        <div className='relative uppercase text-white dark:text-black font-semibold dark:font-bold text-xxs dark:text-xs tracking-wider '>
                            <div className='absolute animate-ping-light'><DictionaryText textKey={'important'} /></div>
                            <div className=''><DictionaryText textKey={'important'} /></div>
                        </div>
                    </div>

                    <div className='w-screen sm:w-auto flex flex-row items-center justify-start gap-4 sm:gap-2 px-4 sm:px-0 overflow-x-auto sm:overflow-visible'>
                        {importantMessage.contentList.map((item, index) =>
                            <InfoBlockItem key={index} data={item} />
                        )}
                    </div>


                </>}

            </div>

        </div>
    );
}

function InfoBlockItem(props) {
    const [hover, setHover] = useState(false);

    return (
        <div className={'flex flex-row items-center justify-center bg-green-7000 ' + (props.className || '')}>

            <Link to={getValueFromParameterMap(props.data, 'url')} className='relative text-left group hidden sm:block' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

                <div className='h-6 flex flex-row gap-1 items-center px-3 bg-tv-yellow group-hover:bg-tv-yellow'>
                    <div className='text-xs dark:text-sm font-semibold tracking-wider whitespace-nowrap'>{getValueFromParameterMap(props.data, 'barTitle')}</div>
                    <motion.div
                        initial={hover ? 'visible' : 'hidden'}
                        animate={hover ? 'visible' : 'hidden'}
                        variants={{ 'visible': { rotate: -90 }, 'hidden': { rotate: 0 } }}>
                        <DynamicIcon className='text-xxs dark:text-xs text-black transition group-hover:text-black' iconName={'FaChevronDown'} />
                    </motion.div>
                </div>

                <motion.div
                    className='absolute min-w-full left-0 bottom-0 h-0'
                    transition={{ ease: 'linear', duration: .2 }}
                    style={{ originY: 0, transformPerspective: 500 }}
                    initial={hover ? 'visible' : 'hidden'}
                    animate={hover ? 'visible' : 'hidden'}
                    variants={{ 'visible': { opacity: 1, rotateX: 0 }, 'hidden': { opacity: 0.5, rotateX: -90.1 } }}>
                    <div className='bg-tv-yellow w-full px-3 pb-3 pt-1 tracking-wide flex flex-col gap-1.5'>
                        <div className='font-bold text-sm dark:text-base uppercase'>{getValueFromParameterMap(props.data, 'title')}</div>
                        <div className='text-xs dark:text-sm dark:font-semibold'>{getValueFromParameterMap(props.data, 'content')}</div>
                    </div>
                </motion.div>

            </Link>

            <Link to={getValueFromParameterMap(props.data, 'url')} className='flex sm:hidden h-6 px-0 sm:px-3 flex-row gap-1 items-center'>
                <div className='text-xxs dark:text-xs text-white dark:text-black font-semibold dark:font-bold tracking-wider whitespace-nowrap'>{getValueFromParameterMap(props.data, 'barTitle')}</div>
                <DynamicIcon className='text-xxs text-tv-yellow transition' iconName={'FaChevronRight'} />
            </Link>

        </div>
    );
}