import React, { useState, useEffect } from 'react';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import ReactResizeDetector from 'react-resize-detector';

import SwipeableViews from 'react-swipeable-views';

import swiperConfig from '@/config/swiperConfig.js'

function Swiper(props) {
    const [currentIndex, setCurrentIndex] = useState(null);
    const [action, setAction] = useState(null);

    const history = useHistory();
    let location = useLocation();

    useEffect(() => {
        let hash = location.hash;
        if (hash === '') hash = null;
        let pathname = location.pathname;
        let indexByHash = null;

        for (let i = 0; i < swiperConfig.screenList.length; i++)
            if (('#' + swiperConfig.screenList[i].hash === hash || !swiperConfig.screenList[i].hash) && swiperConfig.screenList[i].pathname === pathname)
                indexByHash = i;

        setCurrentIndex(indexByHash);
    }, [location]);

    const activateHash = (index) => {
        const screenItem = swiperConfig.screenList[index];
        if (screenItem)
            history.push(screenItem.pathname + (screenItem.hash ? ('#' + screenItem.hash) : ''));
    }

    useEffect(() => {
        updateSwipeableViewHeight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action]);

    const updateSwipeableViewHeight = () => { if (action) action.updateHeight() }

    return (
        <SwipeableViews
            index={currentIndex}
            onChangeIndex={((index, indexLatest, meta) => { activateHash(index) })}
            action={(action) => { setAction(action) }}
            enableMouseEvents
            animateHeight={true}
            className={(props.className || '')}
            slideClassName='w-full'>

            {React.Children.map(props.children, (child, index) => (
                <ReactResizeDetector handleHeight onResize={(width, height) => { updateSwipeableViewHeight(); }} >
                    {React.cloneElement(child, null, null)}
                </ReactResizeDetector>
            ))}

        </SwipeableViews>
    );
}

export default withRouter(Swiper);