import React, { useState } from 'react';

import OverlayHover from '@/elements/OverlayHover.js';
import LightboxLoader from '@/elements/lightbox/LightboxLoader.js';

import Lightbox from 'react-image-lightbox';

export default function ZoomImage(props) {
    const [hover, setHover] = useState(false);
    const [open, setOpen] = useState(false);

    const getUrl = () => {
        if (props.url)
            return props.url;
        else if (props.image)
            return props.image.downloadUrl;
    }

    const getZoomUrl = () => {
        if (props.zoomUrl)
            return props.zoomUrl;
        else if (props.zoomImage)
            return props.zoomImage.downloadUrl;
        else
            return getUrl();
    }

    return (
        <>
            <div
                className={'relative bg-tv-gray-lightest cursor-pointer overflow-hidden bg-center bg-no-repeat bg-cover ' + (props.className || '') + ' ' + ((!props.asImg && props.imageClassName) ? props.imageClassName : '')}
                style={{ backgroundImage: 'url(' + (!props.asImg && getUrl()) + ')' }}
                alt={props.alt}
                onClick={() => setOpen(true)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>

                {props.asImg && <img className={(props.asImg && props.imageClassName) ? props.imageClassName : ''} alt={props.alt} src={getUrl()} />}

                <OverlayHover hover={hover} iconName='FaSearch' label='Kép nagyítása' title={props.title} />

            </div>

            {open && (
                <Lightbox
                    mainSrc={getZoomUrl()}
                    onCloseRequest={() => setOpen(false)}
                    loader={<LightboxLoader />}
                    closeLabel='Bezárás'
                    zoomInLabel='Nagyítás'
                    zoomOutLabel='Kicsinyítés'
                    nextLabel='Következő'
                    prevLabel='Előző'
                />
            )}
        </>
    );
}

