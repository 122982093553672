import nBossConnection from '@/services/nBossConnection.js';

class QueryControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'queryControl';
    }

    /**
    * @param {searchQuery } searchQuery 
    */
    query(searchQuery) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/query`, { searchQuery }).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new QueryControl();
