import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { getWebsite } from '@/utils/WebsiteUtils.js';
import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

export default function QuickBar(props) {
    const [hover/*, setHover*/] = useState(false);

    return (
        <div className={'bg-tv-red dark:bg-tv-yellow flex justify-center ' + (props.className || '')}>

            <div className='w-full min-h-16 max-w-tv-boxed flex flex-col sm:flex-row gap-1 sm:gap-2 md:gap-4 mx-4 sm:mx-2 md:mx-4 py-4 sm:py-2 md:py-4' /*onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}*/>
                {getValueFromParameterMap(getWebsite().globalContent, 'menu') ? getValueFromParameterMap(getWebsite().globalContent, 'menu').itemList.map((item) => <QuickBarItem key={item.id} data={item} open={hover} />) : null}
            </div>

        </div>
    );
}

function QuickBarItem(props) {
    const [hover, setHover] = useState(false);

    return (
        <div className={'relative flex-1 group ' + (props.className || '')} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

            <Link
                to={/*'/' +*/ props.data.fullPath}
                className={'relative p-4 bg-tv-purple dark:bg-tv-gray-darkest flex justify-center items-center text-white text-center text-xs sm:text-xxs md:text-xs dark:text-sm font-bold uppercase tracking-widest ' + (props.className || '')}>

                <div>{props.data.title}</div>

                <motion.div
                    className='absolute w-full h-full flex justify-center items-center bg-tv-yellow dark:bg-white text-black'
                    transition={{ ease: 'easeInOut', duration: .3 }}
                    initial={hover ? 'visible' : 'hidden'}
                    animate={hover ? 'visible' : 'hidden'}
                    variants={{ 'visible': { opacity: 1 }, 'hidden': { opacity: 0 } }}>
                    {props.data.title}
                </motion.div>

            </Link>

            {/* {props.data.childList ? <motion.div
                className='w-full overflow-hidden'
                transition={{ ease: 'easeInOut', duration: .3 }}
                initial={props.open ? 'visible' : 'hidden'}
                animate={props.open ? 'visible' : 'hidden'}
                variants={{ 'visible': { opacity: 1, height: 'auto' }, 'hidden': { opacity: 0, height: 0 } }}>

                <div className='px-4 py-2'>
                    {props.data.childList.map((item, index) => (<QuickBarSubItem key={index} data={item} />))}
                </div>

            </motion.div> : null} */}

        </div>
    );
}

// function QuickBarSubItem(props) {
//     const [hover, setHover] = useState(false);

//     return (
//         <div
//             className='flex flex-col border-b last:border-0 border-opacity-10'
//             onMouseEnter={() => { setHover(true) }}
//             onMouseLeave={() => { setHover(false) }}>

//             <Link
//                 className='py-1 flex flex-row justify-between'
//                 to={'/' + props.data.fullPath}>

//                 <motion.span
//                     className='text-xxs text-white font-semibold tracking-wider uppercase'
//                     initial='closed'
//                     animate={props.data.childList ? (hover ? 'open' : 'closed') : (hover ? 'hover' : 'closed')}
//                     variants={{ open: { opacity: 1 }, closed: { opacity: 0.75 }, hover: { opacity: 1 } }}>
//                     {props.data.title}
//                 </motion.span>

//                 <motion.div
//                     className='flex items-center justify-center ml-4'
//                     initial='closed'
//                     animate={props.data.childList ? (hover ? 'open' : 'closed') : (hover ? 'hover' : 'closed')}
//                     variants={{ open: { rotate: -90, opacity: 1 }, closed: { rotate: 0, opacity: 0.3 }, hover: { opacity: 1 } }}>
//                     <DynamicIcon className={'text-xxs text-white'} iconName={props.data.childList ? 'FaChevronDown' : 'FaChevronRight'} />
//                 </motion.div>

//             </Link>

//             {/* {props.data.childList ? <div className='absolute top-0 right-0 z-20'>
//                 <SubItemDropdown level={props.data.level} open={props.data.childList ? hover : false}>
//                     {props.data.childList.map((item, index) => (<QuickBarSubItem key={index} data={item} />))}
//                 </SubItemDropdown>
//             </div> : null} */}

//         </div>
//     );
// }

// function SubItemDropdown(props) {
//     // const animationOrigin = props.animationOrigin || 'y';

//     return (
//         <motion.div
//             className={'absolute top-0 left-0 flex flex-col bg-tv-purple px-4 py-4 ' + (props.className || '')}
//             initial='hidden'
//             animate={props.open ? 'visible' : 'hidden'}
//             variants={{
//                 visible: { opacity: 1, display: 'flex' },
//                 hidden: { opacity: 0, transitionEnd: { display: 'none' } }
//             }}>
//             {props.children}
//         </motion.div>
//     );
// }