import React from 'react';
import Sticky from 'react-stickynode';

import DynamicIcon from '@/elements/DynamicIcon.js';

import globalConfig from '@/config/globalConfig.js'
import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

export default function CorporealMaterials(props) {
    const _data = {
        cim: getValueFromParameterMap(props.data.value, 'cim'),
        kikuldottMeghivo: getValueFromParameterMap(props.data.value, 'kikuldott-meghivo'),
        napirend: getValueFromParameterMap(props.data.value, 'napirend'),
        jegyzokonyv: getValueFromParameterMap(props.data.value, 'jegyzokonyv'),
        jegyzokonyvKotott: getValueFromParameterMap(props.data.value, 'jegyzokonyv-kotott'),
        hatarozatok: getValueFromParameterMap(props.data.value, 'hatarozatok'),
    };

    return (
        <div className={'w-full flex flex-col ' + (props.className || '')}>

            <Sticky top={globalConfig.etc.headerStickyOffset}>
                <div className='flex flex-row bg-tv-red text-white overflow-hidden bg-no-repeat bg-bottom sm:bg-top bg-cover p-4 sm:p-8 ' style={{ backgroundImage: 'url(/assets/images/pattern-red.png)' }}  >

                    <div className='flex flex-col sm:flex-row items-center gap-4 sm:gap-16'>

                        <img className='w-20 sm:w-28' alt='logo' src='/assets/images/logo/shield-text-top-fill-white.svg' />

                        <div className='flex flex-col gap-4 items-stretch sm:items-start'>
                            {_data.cim && <h1 className='text-3xl md:text-4xl font-bold leading-tight text-center sm:text-left'>{_data.cim}</h1>}
                            {_data.kikuldottMeghivo && <DownloadButton label='Kiküldött meghívó' url={_data.kikuldottMeghivo.downloadUrl} />}
                        </div>

                    </div>

                </div>
            </Sticky>


            <div className='flex flex-col gap-2 sm:gap-4 p-4 sm:p-8 bg-fill-base z-10'>

                {_data.napirend && <div className='content'><details>
                    <summary>Napirend</summary>
                    <p className='content ckecontent' dangerouslySetInnerHTML={{ __html: _data.napirend }} />
                </details></div>}

                {(_data.jegyzokonyv && _data.jegyzokonyv.downloadUrl) ?
                    (_data.jegyzokonyvKotott && _data.jegyzokonyvKotott.downloadUrl) ?
                        <div className='content'><details>
                            <summary>Jegyzőkönyv</summary>
                            <div className='py-4 flex flex-col gap-2'  >
                                {_data.jegyzokonyv.downloadUrl && <DownloadButton label='Jegyzőkönyv letöltése' url={_data.jegyzokonyv.downloadUrl} />}
                                {_data.jegyzokonyvKotott.downloadUrl && <DownloadButton label='Kötött ülés jegyzőkönyv letöltése' url={_data.jegyzokonyvKotott.downloadUrl} />}
                            </div>
                        </details>
                        </div> : (_data.jegyzokonyv.downloadUrl && <DownloadButton label='Jegyzőkönyv letöltése' url={_data.jegyzokonyv.downloadUrl} />)
                    : null}

                {_data.hatarozatok && <div className='content'><details>
                    <summary>Határozatok</summary>
                    <p className='content ckecontent' dangerouslySetInnerHTML={{ __html: _data.hatarozatok }} />
                </details></div>}

            </div>

        </div>
    );
}


function DownloadButton(props) {
    return (
        <a href={props.url}
            target='_blank'
            rel='noreferrer'
            className='flex flex-row gap-1 items-center justify-center sm:justify-start bg-tv-purple transition hover:bg-tv-yellow text-white hover:text-black px-4 py-2 rounded text-sm font-semibold'>
            <DynamicIcon className='text-sm dark:text-base mr-2' iconName={'FaFileAlt'} />
            {props.label}
        </a>
    )
}