const mapConfig = {
    'markerMap': {
        'default': '/assets/images/marker/marker.svg',
        'government': '/assets/images/marker/marker-government.svg',
        'attraction': '/assets/images/marker/marker-attraction.svg',
        'tender': '/assets/images/marker/marker-tender.svg',
        'medical': '/assets/images/marker/marker-medical.svg',
        'otherService': '/assets/images/marker/marker-other-service.svg'
    },
    'defaultCoordinates': { 'lat': 47.50845, 'lng': 19.0665 },
    'discritPolygonPath': [
        { "lat": 47.51949928355869, "lng": 19.071237820291497 },
        { "lat": 47.51118067064461, "lng": 19.080850857400872 },
        { "lat": 47.507557169130735, "lng": 19.0726969419956 },
        { "lat": 47.503310106948064, "lng": 19.0656373678684 },
        { "lat": 47.501846022936554, "lng": 19.06244017472265 },
        { "lat": 47.500106464104334, "lng": 19.060015457773186 },
        { "lat": 47.49919317264365, "lng": 19.058255928659417 },
        { "lat": 47.49757858645295, "lng": 19.05533984456902 },
        { "lat": 47.49780691782885, "lng": 19.0548141316021 },
        { "lat": 47.50987303786257, "lng": 19.055183252966735 },
        { "lat": 47.51582510029396, "lng": 19.05971600297443 },
        { "lat": 47.516738102403245, "lng": 19.061539905104556 },
        { "lat": 47.517926430559996, "lng": 19.06366421464435 },
        { "lat": 47.516520722389586, "lng": 19.06546665910236 },
        { "lat": 47.51943666441447, "lng": 19.07108931313964 },
    ],
    'styles': [
        {
            'featureType': 'poi',
            'elementType': 'labels',
            'stylers': [
                { 'visibility': 'off' }
            ]
        },
        {
            'featureType': 'all',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'saturation': 36
                },
                {
                    'color': '#333333'
                },
                {
                    'lightness': 40
                }
            ]
        },
        {
            'featureType': 'all',
            'elementType': 'labels.text.stroke',
            'stylers': [
                {
                    'visibility': 'on'
                },
                {
                    'color': '#ffffff'
                },
                {
                    'lightness': 16
                }
            ]
        },
        {
            'featureType': 'all',
            'elementType': 'labels.icon',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'administrative',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#fefefe'
                },
                {
                    'lightness': 20
                }
            ]
        },
        {
            'featureType': 'administrative',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#fefefe'
                },
                {
                    'lightness': 17
                },
                {
                    'weight': 1.2
                }
            ]
        },
        {
            'featureType': 'administrative.locality',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#f04e3e'
                }
            ]
        },
        {
            'featureType': 'landscape',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#f5f5f5'
                },
                {
                    'lightness': 20
                }
            ]
        },
        {
            'featureType': 'poi',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#f5f5f5'
                },
                {
                    'lightness': 21
                }
            ]
        },
        {
            'featureType': 'poi.park',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#dedede'
                },
                {
                    'lightness': 21
                }
            ]
        },
        {
            'featureType': 'road.highway',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#ffffff'
                },
                {
                    'lightness': 17
                }
            ]
        },
        {
            'featureType': 'road.highway',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#ffffff'
                },
                {
                    'lightness': 29
                },
                {
                    'weight': 0.2
                }
            ]
        },
        {
            'featureType': 'road.arterial',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ffffff'
                },
                {
                    'lightness': 18
                }
            ]
        },
        {
            'featureType': 'road.local',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ffffff'
                },
                {
                    'lightness': 16
                }
            ]
        },
        {
            'featureType': 'transit',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#f2f2f2'
                },
                {
                    'lightness': 19
                }
            ]
        },
        {
            'featureType': 'water',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#e9e9e9'
                },
                {
                    'lightness': 17
                }
            ]
        }
    ],
}

export default mapConfig;