import React, { useState } from 'react';

import DynamicIcon from '@/elements/DynamicIcon.js';

import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

export default function InputCard(props) {
    const [hover, setHover] = useState(null);

    let _data = {
        heading: getValueFromParameterMap(props.data, 'cim-szines-fejlec-tartalma'),
        icon: getValueFromParameterMap(props.data, 'ikon-felso') && getValueFromParameterMap(props.data, 'ikon-felso').downloadUrl,
        title: getValueFromParameterMap(props.data, 'szoveg-belso-cim'),
        content: getValueFromParameterMap(props.data, 'szoveg'),
        question: getValueFromParameterMap(props.data, 'szoveg-also-kerdes'),
        color: getValueFromParameterMap(props.data, 'fejlec-szine'),
        mapImage: getValueFromParameterMap(props.data, 'terkep-kep') && getValueFromParameterMap(props.data, 'terkep-kep').downloadUrl,
        mapContent: getValueFromParameterMap(props.data, 'terkep-szoveg'),
        topic: getValueFromParameterMap(props.data, 'tema-tipus'),
        joker: getValueFromParameterMap(props.data, 'joker'),
    }

    const getRingColorClassName = (color) => {
        if (color === 'ROZSASZIN')
            return 'ring-tv-rose';
        if (color === 'LILA')
            return 'ring-tv-purple';
        else
            return 'ring-tv-red';
    }

    const getBgColorClassName = (color) => {
        if (color === 'ROZSASZIN')
            return 'bg-tv-rose';
        if (color === 'LILA')
            return 'bg-tv-purple';
        else
            return 'bg-tv-red';
    }

    return (
        <div className={'relative w-72 min-h-110 flex flex-col shadow-xl transition filter ' + (props.blur ? ' opacity-50 grayscale ' : ' opacity-100 grayscale-0 ') + (props.selectable ? ' cursor-pointer ' : ' ') + (hover ? ' ring-4 ' + getRingColorClassName(_data.color) + ' ' : ' ') + (props.className || '')}
            onMouseEnter={() => props.selectable && setHover(true)}
            onMouseLeave={() => props.selectable && setHover(false)}
            onClick={() => props.onClick()}>

            {/* <svg className='invisible' viewBox='0 0 17 26' /> */}

            {/* <div className={'absolute -inset-0 w-full h-full items-center flex flex-col'}> */}

            <div className={'relative w-full min-h-16 flex items-center px-6 ' + getBgColorClassName(_data.color)}>
                <div className='text-white text-lg py-4' dangerouslySetInnerHTML={{ __html: _data.heading }} />
                {_data.icon && <img className='absolute -bottom-12 right-6 w-16 h-16 shadow-sm' alt='illustration' src={_data.icon} />}
            </div>

            <div className='flex-1 w-full flex flex-col justify-center gap-4 p-6 bg-tv-gray-lighter overflow-hidden'>

                {(_data.mapImage && _data.mapContent) && <div className='relative w-full h-full'>
                    <img className=' w-60 h-60' alt='map' src={_data.mapImage} />
                    {_data.mapContent && <div className='absolute w-36 bottom-0 right-0 text-xs text-right' dangerouslySetInnerHTML={{ __html: _data.mapContent }} />}
                </div>}

                {(_data.mapContent && !_data.mapImage) && <div className='text-xs' dangerouslySetInnerHTML={{ __html: _data.mapContent }} />}

                {_data.title && <div className='text-md' dangerouslySetInnerHTML={{ __html: _data.title }} />}

                {_data.content && <div className='text-xs' dangerouslySetInnerHTML={{ __html: _data.content }} />}

                {_data.question && <div className='flex flex-row items-center gap-2 mt-2'>
                    <div className='w-8 h-8 bg-tv-purple flex items-center justify-center'>
                        <DynamicIcon className={'text-lg text-white'} iconName='FaQuestion' />
                    </div>
                    <div className='flex-1 text-xs font-bold'>{_data.question}</div>
                </div>}

            </div>

            {_data.topic && <div className='w-full bg-tv-gray-lighter text-xs text-center pb-2 text-tv-purple font-semibold'>{_data.topic}</div>}

            <div className='w-full bg-white min-h-16 flex flex-row justify-between items-center pl-6 pr-3'>
                <img className='h-8' alt='transparency international' src='/assets/images/input-card/transparency-international.svg' />
                <img className='h-12' alt='logo' src='/assets/images/logo/shield-text-top-fill-red.svg' />
            </div>


            {/* </div> */}

        </div >
    )
}