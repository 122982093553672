import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import StickyHeader from '@/elements/StickyHeader';
import DynamicIcon from '@/elements/DynamicIcon.js';

import nBossConnection from '@/services/nBossConnection.js';
import FormFrontendControl from '@/services/FormFrontendControl.js';

export default function Form(props) {
    // const [questionIndex, setQuestionIndex] = useState(0);
    const [form, setForm] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [screen, setScreen] = useState('FORM');

    const history = useHistory();

    useEffect(() => {
        if (props.data && props.data.value) FormFrontendControl.getForm(props.data.value, 0).then(result => { setForm(result) });
    }, [props.data]);

    // useEffect(() => {
    //     console.log(answers)
    // }, [answers]);

    const handleAnswerChange = (key, answer) => {
        let _answers = [];
        for (let i = 0; i < answers.length; i++) _answers[i] = answers[i];
        let hasItem = false;
        for (let i = 0; i < _answers.length; i++) { if (_answers[i].key === key) { _answers[i].answer = answer; hasItem = true; } }
        if (!hasItem) _answers.push({ key: key, answer: answer });
        setAnswers(_answers);
    };

    const getAnswer = (key) => {
        let answer = null; for (let i = 0; i < answers.length; i++) if (answers[i].key === key) answer = answers[i].answer;
        return answer;
    };

    const getQuestionByKey = (key) => {
        for (let i = 0; i < form.questionList.length; i++)
            if (form.questionList[i].key === key)
                return form.questionList[i];
        return null;
    };

    const getAnswerItemByKey = (question, key) => {
        for (let i = 0; i < question.answerList.length; i++)
            if (question.answerList[i].key === key)
                return question.answerList[i];
        return null;
    };

    const getAnswerByKey = (key) => {
        for (let i = 0; i < answers.length; i++)
            if (answers[i].key === key)
                return answers[i].answer;
        return null;
    };

    const getFormatedAnswer = (key) => {
        let answer = null;
        if (getQuestionByKey(key).questionType === 'SELECT_ONE' || getQuestionByKey(key).questionType === 'SELECT_ANY') {
            let answerItem = getAnswerItemByKey(getQuestionByKey(key), getAnswerByKey(key));
            if (answerItem != null)
                answer = answerItem.title;
        } else if (getQuestionByKey(key).questionType === 'FILE_UPLOAD') {
            if (getAnswerByKey(key) && getAnswerByKey(key).length) {
                answer = '';
                for (let i = 0; i < getAnswerByKey(key).length; i++) answer += (i ? ', ' : '') + getAnswerByKey(key)[i].fileName
            }
        } else {
            answer = getAnswerByKey(key);
        }
        return answer
    };

    const isFinished = () => {
        for (let i = 0; i < form.questionList.length; i++)
            if (!getAnswerByKey(form.questionList[i].key))
                return false;
        return true;
    };

    const send = () => {
        let _result = {};
        _result.questionIndex = 0;
        _result.surveyKey = form.key;

        let answerMap = {};
        for (let i = 0; i < answers.length; i++) {
            if (getQuestionByKey(answers[i].key).questionType === 'FILE_UPLOAD') {
                let _answer = '';
                for (let j = 0; j < answers[i].answer.length; j++) _answer += (j ? ';' : '') + answers[i].answer[j].key;
                answerMap[answers[i].key] = _answer;
            } else if (getQuestionByKey(answers[i].key).questionType === 'AMOUNT') {
                answerMap[answers[i].key] = parseInt(answers[i].answer);
            } else {
                answerMap[answers[i].key] = answers[i].answer;
            }
        }
        _result.answers = answerMap;

        FormFrontendControl.submit(_result, 0).then(result => {
            if (result && result.successful)
                setScreen('FEEDBACK');
        });
    };

    return (
        <div className={'w-full flex flex-col min-h-full ' + (props.className || '')}>

            {(form && (form.title || form.description)) && <StickyHeader disabled small heading={form.title} content={form.description} />}

            {form && <div className='w-full flex p-4 sm:p-6'>

                {screen === 'FORM' && <div className='w-full flex flex-col  gap-4 sm:gap-6'>

                    <div className='w-full grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6'>

                        {form.questionList.map((item, index) => <Input
                            key={item.key}
                            index={index}
                            question={item}
                            answer={getAnswer(item.key)}
                            onAnswerChanged={answer => handleAnswerChange(item.key, answer)} />)}

                    </div>

                    <button className='w-full btn' disabled={props.prevDisabled} onClick={() => setScreen('CHECK')}>
                        Megadott adatok ellenőrzése
                    </button>

                </div>}

                {screen === 'CHECK' && <div className='w-full flex flex-col justify-center space-y-6 flex-1'>
                    <div className='dark:text-white flex flex-col space-y-2 text-center'>

                        {(form && form.checkTitle) && <div className='text-3xl sm:text-4xl font-bold'>{form.checkTitle}</div>}
                        {(form && form.checkMessage) && <section className='text-base' dangerouslySetInnerHTML={{ __html: form.checkMessage }} />}

                        <div className='flex flex-col space-y-2 pt-2'>
                            {form.questionList.map((item, index) => <SummaryItem
                                key={index}
                                index={index}
                                question={item.title}
                                answer={getFormatedAnswer(item.key)} />)}
                        </div>

                        {!isFinished() && <div className='rounded bg-tv-red p-2 text-white font-bold'>Kérjük, ellenőrizze a kérdéseket, és adjon választ az összes kérdésre!</div>}

                        <div className='flex flex-col md:flex-row gap-4 sm:gap-4'>
                            <button className='w-full btn' onClick={() => setScreen('FORM')}>Vissza</button>
                            <button className='w-full btn' disabled={!isFinished()} onClick={() => send()}>Adatok beküldése</button>
                        </div>

                    </div>
                </div>}

                {screen === 'FEEDBACK' && <div className='w-full flex flex-col justify-center space-y-6 flex-1'>
                    <div className='dark:text-white  flex flex-col space-y-2 text-center'>

                        {(form && form.feedbackTitle) && <div className='text-3xl sm:text-4xl font-bold'>{form.feedbackTitle}</div>}
                        {(form && form.feedbackMessage) && <section className='text-base' dangerouslySetInnerHTML={{ __html: form.feedbackMessage }} />}

                    </div>
                </div>}

            </div>}

        </div>
    );
}

function Input(props) {

    const getTitle = () => {
        return props.question.title.replace('adatvédelmi tájékoztatót', '<a href="/oldal/adatkezelesi-tajekoztato">adatvédelmi tájékoztatót</a>') + "";
    };

    return (
        <div className={'gap-3 ' + (props.question.questionType === 'SELECT_ONE' ? 'col-span-full flex flex-row' : 'flex flex-col')}>

            {props.question.questionType === 'SELECT_ONE' && <CheckboxList answerList={props.question.answerList} onAnswerChanged={(answer) => props.onAnswerChanged(answer)} answer={props.answer} />}

            <div className='flex items-center gap-1'>
                {props.question.questionType !== 'SELECT_ONE' && <span className='font-bold text-sm'>{(props.index + 1) + '.'}</span>}
                <div className={'font-semibold text-sm ckecontent ' + (props.question.questionType === 'SELECT_ONE' ? '' : '')} dangerouslySetInnerHTML={{ __html: getTitle() }}></div>
                {(!props.question.questionType === 'SELECT_ONE' || (props.question.questionType === 'SELECT_ONE' && props.question.answerList.length < 2)) && <span className='font-bold text-tv-red'> *</span>}
            </div>

            {props.question.questionType === 'FILE_UPLOAD' && <MultipleFileUpload onAnswerChanged={(answer) => props.onAnswerChanged(answer)} answer={props.answer} />}
            {(props.question.questionType === 'STRING' || props.question.questionType === 'ADDRESS' || props.question.questionType === 'EMAIL' || props.question.questionType === 'AMOUNT' || props.question.questionType === 'PHONE') && <TextInput postfix={props.question.questionType === 'AMOUNT' ? 'Ft' : null} number={props.question.questionType === 'AMOUNT'} answer={props.answer} onAnswerChanged={(answer) => props.onAnswerChanged(answer)} />}

        </div>
    );
}

function TextInput(props) {
    return (
        <div className='flex items-center gap-2'>
            <input
                // placeholder={genericPlaceholder}
                className='flex-1 generic-input'
                value={props.answer || ''}
                maxLength={props.inputLength}
                type={props.number ? 'number' : 'text'}
                // inputMode='numeric' pattern='[0-9]*'
                step='1'
                onChange={e => props.onAnswerChanged(e.target.value !== '' ? e.target.value : null)} />
            {props.postfix && <div>{props.postfix}</div>}
        </div>
    );
}

function SummaryItem(props) {
    return (
        <div className='w-full flex flex-col sm:flex-row items-center gap-2 sm:gap-4 rounded odd:bg-tv-gray-light even:bg-tv-gray-lightest p-2'>
            <div className='flex-1 font-bold text-center sm:text-right'>{props.question}</div>
            <div className='flex-1 text-center sm:text-left'>{props.answer ? props.answer : 'Nem megadott'}</div>
        </div>
    );
}

function MultipleFileUpload(props) {
    const [fileList, setFileList] = useState([]);

    const max = 5;

    // useEffect(() => {
    // console.log(fileList);
    // props.onAnswerChanged(fileList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [fileList]);

    useEffect(() => {
        if (props.answer)
            setFileList(props.answer);
    }, [props.answer]);

    const addAnswer = (key, fileName) => {
        let _fileList = [];
        for (let i = 0; i < fileList.length; i++) _fileList.push(fileList[i])
        _fileList.push({ key: key, fileName: fileName });
        setFileList(_fileList);
        props.onAnswerChanged(_fileList);
    }

    const removeAnswer = (key) => {
        let _fileList = [];
        for (let i = 0; i < fileList.length; i++) _fileList.push(fileList[i])
        let index = null;
        for (let i = 0; i < _fileList.length; i++) if (_fileList[i].key === key) index = i;
        _fileList.splice(index, 1);
        setFileList(_fileList);
        props.onAnswerChanged(_fileList);
    }

    return (
        <div className={'w-full flex flex-col gap-2 ' + (props.className || '')}>

            {(fileList.length < max) && <FileUpload onUpload={(key, fileName) => { addAnswer(key, fileName) }} onDelete={key => { removeAnswer(key) }} />}

            <div className='flex flex-col gap-2'>
                {fileList.map((item) => <FileUpload key={item.key} itemKey={item.key} fileName={item.fileName} onDelete={key => { removeAnswer(key) }} />)}
            </div>

        </div>
    );
}

function FileUpload(props) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [key, setKey] = useState(null);
    const [uploadingFileName, setUploadingFileName] = useState(null);
    const [uploadingPercentage, setUploadingPercentage] = useState(0);

    const service = 'fileRepository';

    const handleFileChange = e => {
        setSelectedFile(e.target.files[0]);
    };

    useEffect(() => {
        if (selectedFile != null) {
            const formData = new FormData();
            formData.append('file', selectedFile, selectedFile.name);

            setUploadingFileName(selectedFile.name);
            setUploadingPercentage(0);
            setIsUploading(true);

            nBossConnection.connection.post(`${service}/addFile`, formData, { onUploadProgress: progressEvent => { setUploadingPercentage((progressEvent.loaded / progressEvent.total) * 100); } })
                .then(response => {
                    setIsUploading(false);
                    // setKey(response.data);
                    setSelectedFile(null);
                    props.onUpload(response.data, selectedFile.name);
                })
                .catch(e => {
                    console.log(e);
                    setIsUploading(false);
                    setSelectedFile(null);
                    // dispatch(showMessage({ message: t('FILE_UPLOADING_ERROR') }));
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile]);

    useEffect(() => {
        setKey(props.itemKey)
        setUploadingFileName(props.fileName)
    }, [props.itemKey, props.fileName]);

    return (
        <div className={'text-white cursor-pointer transition font-semibold text-sm  hover:bg-tv-yellow ' + ((isUploading || key) ? 'bg-tv-gray-dark text-tv-gray-darker' : 'bg-tv-purple ')}>

            {(!isUploading && !key) && <button className='relative w-full flex items-center justify-between px-3 py-2'>
                <div className=''>Fájl kiválasztása</div>
                <DynamicIcon className='text-base' iconName={'FaFileUpload'} />
                <input className='absolute cursor-pointer inset-0 w-full h-full bg-red-400' type='file' onChange={e => handleFileChange(e)} style={{ opacity: 0.0001 }} />
            </button>}

            {isUploading && <div className='w-full flex flex-col justify-center items-center h-full px-3 py-2'>
                {uploadingPercentage < 100 ? (<div className='w-full flex flex-col items-center'>
                    <div className='font-bold'>Feltöltés folyamatban</div>
                    <div className='h-2 bg-tv-gray-darker' style={{ width: 100 + '%' }}></div>
                </div>) : (<div>Feltöltés kész</div>)}
                <div>{uploadingFileName}</div>
            </div>}

            {key && <button className='w-full flex gap-3 items-center justify-between px-3 py-2' onClick={() => props.onDelete(key)}>
                <div className='flex-1 text-left truncate'><span className='font-bold'>Feltöltve:</span> {uploadingFileName}</div>
                <DynamicIcon className='text-base' iconName={'FaTrash'} />
            </button>}

        </div>
    );
}

function CheckboxList(props) {
    useEffect(() => {
        if (props.answerList.length > 1)
            if (!isSelected(props.answerList[0].key))
                handleAnswer(props.answerList[props.answerList.length - 1].key);
    }, [props.answerList]);

    const handleAnswer = (key) => {
        if (!isSelected(key)) {
            if (props.multiple) {
                let arr = answerToArr(props.answer); arr.push(key); props.onAnswerChanged(answerToStr(arr))
            } else {
                if (props.answerList.length > 1) {

                    if (key === props.answerList[0].key) {
                        props.onAnswerChanged(key)
                    } else {
                        props.onAnswerChanged(props.answerList[props.answerList.length - 1].key)
                    }

                    // if (!isSelected(props.answerList[0].key))
                    //     handleAnswer(props.answerList[props.answerList.length - 1].key);
                } else {
                    props.onAnswerChanged(key)
                }
            }
        } else {

            if (props.answerList.length > 1) {

                props.onAnswerChanged(props.answerList[props.answerList.length - 1].key)

            } else {

                let arr = answerToArr(props.answer);
                let index = arr.indexOf(key);
                arr.splice(index, 1);
                props.onAnswerChanged(answerToStr(arr))
            }
        }
    };

    const answerToStr = (arr) => { let str = ''; for (var i = 0; i < arr.length; i++)str += (i === 0 ? '' : ',') + arr[i]; return str === '' ? null : str; };
    const answerToArr = (str) => { return str !== null ? str.split(',') : []; };
    const isSelected = (key) => { return answerToArr(props.answer).indexOf(key) > -1; }

    return (
        <div className='flex items-center gap-2'>

            {props.answerList.map((item, index) => <input
                className={index > 0 ? 'hidden' : ''}
                type='checkbox'
                checked={isSelected(item.key)}
                onChange={e => handleAnswer(item.key)} />)}

        </div>
    );
};