import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';

import DynamicIcon from '@/elements/DynamicIcon.js';
import DictionaryText from '@/elements/DictionaryText';

const imageAspectWidth = 16;
const imageAspectHeight = 9;
const duration = 12;
const transitionDuration = .85;

export default function Slider(props) {
    const [activeIndex, setActiveIndex] = useState(null);

    let getNext = () => { return activeIndex + 1 >= props.data.value.parameterMap.itemList.value.contentList.length ? 0 : activeIndex + 1; }
    let getPrev = () => { return activeIndex - 1 < 0 ? props.data.value.parameterMap.itemList.value.contentList.length - 1 : activeIndex - 1; }

    useEffect(() => {
        if (props.data != null)
            setActiveIndex(0);
    }, [props.data])

    useEffect(() => {
        let timer = setTimeout(() => {
            setActiveIndex(getNext());
        }, duration * 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex])

    return (
        <div className={props.className || ''}>

            {!props.mobileOnly ? <DesktopSlider
                className='hidden sm:flex'
                activeIndex={activeIndex}
                onChangeNext={() => setActiveIndex(getNext())}
                onChangePrev={() => setActiveIndex(getPrev())}
                onChangeIndex={index => setActiveIndex(index)}
                data={(props.data && props.data.value.parameterMap.itemList) && props.data.value.parameterMap.itemList.value.contentList} /> : null}

            {!props.desktopOnly ? <MobileSlider
                className='block sm:hidden'
                activeIndex={activeIndex}
                onChangeNext={() => setActiveIndex(getNext())}
                onChangePrev={() => setActiveIndex(getPrev())}
                onChangeIndex={index => setActiveIndex(index)}
                data={(props.data && props.data.value.parameterMap.itemList) && props.data.value.parameterMap.itemList.value.contentList} /> : null}

        </div>
    );
}

function DesktopSlider(props) {
    const [dimensions, setDimensions] = useState({});
    const [hover, setHover] = useState(false);

    const container = useRef();

    useResizeObserver({
        ref: container,
        onResize: ({ width, height }) => {
            let unit = imageAspectHeight + imageAspectWidth, containerW = width, textContainerDim = (containerW / unit) * imageAspectHeight;
            setDimensions({ imageWidth: (containerW / unit) * imageAspectWidth, textWidth: textContainerDim, height: textContainerDim });
        }
    });

    return (
        <div className={'flex-row gap-0.5 ' + (props.className || '')} ref={container} style={{ height: dimensions ? (dimensions.height + 'px') : '0px' }}>

            <div className='relative flex overflow-hidden bg-tv-gray-light dark:bg-tv-gray-darkest' style={{ width: dimensions.imageWidth + 'px', height: dimensions.height + 'px' }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

                {props.data ? <div className='relative w-full h-full'>
                    {props.data.map((item, index) => <SlideImage key={index} active={props.activeIndex === index} data={item} />)}
                </div> : null}

                {props.data && props.data[props.activeIndex] ? <>
                    <div className='absolute w-full h-full pb-1 flex flex-row justify-between'>
                        <SlideNavigationButton className='w-auto' contentClassName='w-10' visible={hover} iconName='FaChevronLeft' onClick={() => props.onChangePrev()} />
                        <Link className='flex-1' to={props.data[props.activeIndex].parameterMap['url'].value}></Link>
                        <SlideNavigationButton className='w-auto' contentClassName='w-10' right visible={hover} iconName='FaChevronRight' onClick={() => props.onChangeNext()} />
                    </div>
                </> : null}

            </div>

            <div className='relative flex overflow-hidden bg-tv-red-dark dark:bg-tv-gray-darkest' style={{ width: dimensions.textWidth + 'px', height: dimensions.height + 'px' }}>

                {props.data ? <div className='relative w-full h-full'>
                    {props.data.map((item, index) => <DesktopSliderSlideContent dimensions={dimensions} key={index} active={props.activeIndex === index} data={item} />)}
                </div> : null}

                {props.data ? <div className='absolute bottom-0 right-0 h-5 mx-6 mb-6 flex flex-row items-center justify-end gap-1 z-10'>
                    {props.data.map((item, index) => <SliderOrb key={index} index={index} active={props.activeIndex === index} onChangeIndex={props.onChangeIndex} />)}
                </div> : null}

            </div>

        </div >
    );
}

function DesktopSliderSlideContent(props) {
    return (
        <div className={'absolute w-full h-full flex flex-col p-6 text-white ' + (props.active ? 'z-10' : 'z-0')}>

            <div className='flex flex-col h-full text-white overflow-hidden'>

                <div className='flex flex-row gap-6 items-start'>

                    <motion.h1
                        className='flex-1 uppercase font-bold text-xl md:text-2xl dark:text-2xl md:dark:text-3xl tracking-normal md:tracking-wider dark:text-tv-yellow-light'
                        // style={{ transformPerspective: 2500 }}
                        transition={{ duration: transitionDuration }}
                        initial={props.active ? 'in' : 'out'}
                        animate={props.active ? 'in' : 'out'}
                        variants={{ 'in': { opacity: 1, rotateX: 1 }, 'out': { opacity: 0, rotateX: 90 } }}
                    >{props.data.parameterMap['title'].value}</motion.h1>

                    {props.data.parameterMap['icon'].value && <motion.div
                        className='w-8 h-8 md:w-10 md:h-10 my-1 hidden md:block'
                        transition={{ duration: transitionDuration }}
                        initial={props.active ? 'in' : 'out'}
                        animate={props.active ? 'in' : 'out'}
                        variants={{ 'in': { opacity: 1, scale: 1 }, 'out': { opacity: 0, scale: 0.5 } }}>
                        <img className={'w-full h-full'} alt='icon' src={props.data.parameterMap['icon'].value.downloadUrl} />
                    </motion.div>}

                </div>

                <motion.div
                    className='relative flex-1 mt-4 overflow-hidden'
                    transition={{ duration: transitionDuration }}
                    initial={props.active ? 'in' : 'out'}
                    animate={props.active ? 'in' : 'out'}
                    variants={{ 'in': { opacity: 1, rotateX: 0 }, 'out': { opacity: 0, rotateX: -90 } }}>
                    <div className='absolute top-0 left-0 text-xs md:text-sm dark:text-sm md:dark:text-sm font-thin' dangerouslySetInnerHTML={{ __html: props.data.parameterMap['content'].value }} />
                    <div className='absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-tv-red-dark dark:from-tv-gray-darkest' />
                </motion.div>

            </div>

            {/* <motion.div
                className='w-min mt-4'
                transition={{ duration: transitionDuration }}
                initial={props.active ? 'in' : 'out'}
                animate={props.active ? 'in' : 'out'}
                variants={{ 'in': { opacity: 1, rotateX: 0 }, 'out': { opacity: 0, rotateX: -90 } }}> */}
            <Link className={'text-tv-yellow-light uppercase font-bold text-xs dark:text-base tracking-widest ' + (props.active ? 'block' : 'hidden')} to={props.data.parameterMap['url'].value}><DictionaryText textKey={'read-more'} /></Link>
            {/* </motion.div> */}

        </div>
    );
}

function MobileSlider(props) {
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const container = useRef();

    useResizeObserver({
        ref: container,
        onResize: ({ width, height }) => {
            if (container && container.current) {
                setWidth(width);
                setHeight(window.innerHeight - container.current.getBoundingClientRect().y);
            }
        }
    });

    return (
        <div className={'w-full flex flex-col ' + (props.className || '')} ref={container} style={{ height: height ? (height + 'px') : '0px' }}>

            <div className='relative w-full bg-tv-gray-lighter' style={{ height: width ? ((width * (imageAspectHeight / imageAspectWidth)) + 'px') : '0px' }}>
                {props.data ? <div className='relative w-full h-full'>
                    {props.data.map((item, index) => <SlideImage key={index} active={props.activeIndex === index} data={item} />)}
                </div> : null}

                {props.data && props.data[props.activeIndex] ? <>
                    <div className='absolute w-full h-full pb-1 flex flex-row top-0 justify-between'>
                        <SlideNavigationButton className='w-auto' contentClassName='w-7' hoverDisabled visible iconName='FaChevronLeft' onClick={() => props.onChangePrev()} />
                        <Link className='flex-1' to={props.data[props.activeIndex].parameterMap['url'].value}></Link>
                        <SlideNavigationButton className='w-auto' contentClassName='w-7' hoverDisabled right visible iconName='FaChevronRight' onClick={() => props.onChangeNext()} />
                    </div>
                </> : null}

                {props.data ? <div className='absolute bottom-0 w-full h-10 flex flex-row items-center justify-center gap-1'>
                    {props.data.map((item, index) => <SliderOrb key={index} index={index} active={props.activeIndex === index} onChangeIndex={props.onChangeIndex} />)}
                </div> : null}
            </div>

            <div className='w-full flex-1 overflow-hidden p-4'>

                {props.data ? <div className='relative w-full h-full'>
                    {props.data.map((item, index) => <MobileSliderSlideContent key={index} active={props.activeIndex === index} data={item} />)}
                </div> : null}

            </div>

            <div className='bg-tv-gray-light dark:bg-tv-yellow w-full h-8 flex items-center justify-center'>
                <DynamicIcon className='text-md text-tv-gray-dark dark:text-black' iconName={'FaChevronDown'} />
            </div>

        </div>
    );
}

function MobileSliderSlideContent(props) {
    return (
        <motion.div
            className={'flex flex-col h-full ' + (props.active ? '' : 'absolute top-0')}
            transition={{ duration: transitionDuration }}
            initial={'out'}
            animate={props.active ? 'in' : 'out'}
            variants={{ 'in': { opacity: 1, display: 'flex' }, 'out': { opacity: 0, transitionEnd: { display: 'none' } } }}>

            <motion.h1
                className='uppercase text-md dark:text-lg font-bold tracking-tight text-tv-red dark:text-tv-yellow'
                transition={{ duration: transitionDuration }}
                initial={props.active ? 'in' : 'out'}
                animate={props.active ? 'in' : 'out'}
                variants={{ 'in': { opacity: 1, rotateX: 0 }, 'out': { opacity: 0, rotateX: -90 } }}>
                {props.data.parameterMap['title'].value}
            </motion.h1>

            <motion.div
                className='relative flex-1 overflow-hidden mt-2 mb-4'
                transition={{ duration: transitionDuration }}
                initial={props.active ? 'in' : 'out'}
                animate={props.active ? 'in' : 'out'}
                variants={{ 'in': { opacity: 1, rotateX: 0 }, 'out': { opacity: 0, rotateX: -90 } }}>
                <div className='text-xs dark:text-sm dark:text-white' dangerouslySetInnerHTML={{ __html: props.data.parameterMap['content'].value }} />
                <div className='absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-white dark:from-tv-gray-darkest' />
            </motion.div>

            {/* {console.log(props.data.parameterMap['url'])} */}

            <Link className='w-full flex' to={props.data.parameterMap['url'].value}>
                <motion.button
                    className='w-full bg-tv-red dark:bg-tv-yellow flex justify-between items-center'
                    transition={{ duration: transitionDuration }}
                    initial={props.active ? 'in' : 'out'}
                    animate={props.active ? 'in' : 'out'}
                    variants={{ 'in': { opacity: 1, scale: 1 }, 'out': { opacity: 0, scale: 0.5 } }}>

                    <div className='flex flex-col gap-0.5 text-left text-white dark:text-black m-3'>
                        <div className='text-xs dark:text-sm font-semibold dark:font-bold tracking-wider uppercase'><DictionaryText textKey={'read-more'} /></div>
                        <span className='text-xxs dark:text-xs font-thin dark:font-normal normal-case'>{props.data.parameterMap['title'].value + ' ››'}</span>
                    </div>

                    <div className='w-14 h-full bg-tv-red-dark dark:bg-tv-purple flex items-center justify-center'>
                        {props.data.parameterMap['icon'].value && <img className={'w-full h-full p-3'} alt='icon' src={props.data.parameterMap['icon'].value.downloadUrl} />}
                        {!props.data.parameterMap['icon'].value && <DynamicIcon className='text-2xl dark:text-2xl text-tv-yellow-light' iconName={'FaBookOpen'} />}
                    </div>

                </motion.button>
            </Link>

        </motion.div>
    );
}

function SlideImage(props) {

    return (
        <motion.div
            className='absolute w-full h-full flex overflow-hidden'
            transition={{ duration: transitionDuration }}
            initial={'hidden'}
            animate={props.active ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1 }, 'hidden': { opacity: 0 } }}>


            {props.data.parameterMap['image'].value &&
                <motion.div
                    className='bg-cover bg-center w-full h-full'
                    style={{ backgroundImage: 'url(' + props.data.parameterMap['image'].value.downloadUrl + ')' }}
                    transition={{ ease: 'linear', duration: duration }}
                    initial={'start'}
                    animate={props.active ? 'finish' : 'start'}
                    variants={{ 'start': { scale: 1.1, rotate: 0.25, transition: { duration: transitionDuration } }, 'finish': { scale: 1, rotate: 0 } }}>
                </motion.div>}

            <div className='absolute w-full h-1 bg-white bottom-0 bg-opacity-50 backdrop-filter backdrop-blur-sm' >
                <motion.div
                    className='h-full bg-white bg-opacity-50'
                    transition={{ ease: 'linear', duration: duration }}
                    initial={'start'}
                    animate={props.active ? 'finish' : 'start'}
                    variants={{ 'start': { width: '0%', transition: { duration: transitionDuration } }, 'finish': { width: '100%' } }} />
            </div>

        </motion.div>
    );
}

function SlideNavigationButton(props) {
    const [hover, setHover] = useState(false);

    return (
        <button className={'relative h-full ' + (props.className || '')} onClick={() => props.onClick()} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <motion.div
                className={'absolute top-0 h-full bg-white dark:bg-tv-yellow bg-opacity-40 dark:bg-opacity-100 z-10 flex items-center justify-center shadow-xl backdrop-filter backdrop-blur-sm ' + (props.right ? 'right-0 ' : 'left-0 ') + (props.contentClassName || '')}
                transition={{ ease: 'linear' }}
                style={{ originX: props.right ? 1 : 0, transformPerspective: 500 }}
                initial={props.visible ? 'visible' : 'hidden'}
                animate={props.visible ? 'visible' : 'hidden'}
                variants={{ 'visible': { opacity: hover && !props.hoverDisabled ? 1 : 0.55, rotateY: 0 }, 'hidden': { opacity: 0, rotateY: props.right ? -90 : 90 } }}>

                <DynamicIcon className='text-md dark:text-lg text-white dark:text-tv-gray-darker' iconName={props.iconName} />

            </motion.div>
        </button>
    );
}

function SliderOrb(props) {
    return (<button onClick={() => props.onChangeIndex(props.index)} className={'w-2 h-2 dark:w-3 dark:h-3 rounded-full bg-white transform transition-all scale-100 hover:scale-125 ' + (props.active ? 'bg-opacity-90' : 'bg-opacity-70')} />);
}