import React from 'react';

import Modal, { ModalButton, ModalHeader, ModalContent } from '@/elements/modal/Modal';

export default function ModalDialog(props) {
    return (
        <Modal {...props}>

            <ModalHeader {...props}>{props.title}</ModalHeader>

            <ModalContent {...props}>

                <div className='text-sm content' dangerouslySetInnerHTML={{ __html: props.content }} />

                <div className='flex flex-col gap-2'>
                    <ModalButton label={props.primaryButtonLabel} onClick={() => props.onPrimaryButtonClick()} />
                    <ModalButton secondary label={props.secondaryButtonLabel} onClick={() => props.onSecondaryButtonClick()} />
                </div>

            </ModalContent>

        </Modal>
    );
}