import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import StickyHeader from '@/elements/StickyHeader';

import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

export default function FormDescription(props) {
    const [termsAccepted, setTermsAccepted] = useState(null);

    const history = useHistory();

    let _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        description: getValueFromParameterMap(props.data, 'description'),
        fileLink: getValueFromParameterMap(props.data, 'file-link'),
        formRef: getValueFromParameterMap(props.data, 'form-ref') && getValueFromParameterMap(props.data, 'form-ref').fullPath,
        descriptionWhite: getValueFromParameterMap(props.data, 'description-white'),
        termsLabel: getValueFromParameterMap(props.data, 'elfogadas-szoveg'),
    }

    return (
        <div className={'w-full flex flex-col ' + (props.className || '')}>

            <StickyHeader htmlHeading={_data.title} content={_data.descriptionWhite} />

            <div className='flex-1 flex flex-col gap-4 sm:gap-8 p-4 sm:p-8 bg-fill-base z-10'>

                {_data.description && <section className='content' dangerouslySetInnerHTML={{ __html: _data.description }} />}

                <div className='border p-4 flex flex-row items-center gap-4 cursor-pointer' onClick={() => setTermsAccepted(!termsAccepted)}>
                    <input className='' type='checkbox' checked={termsAccepted} />
                    <section dangerouslySetInnerHTML={{ __html: _data.termsLabel }} />
                </div>

                <div className='flex flex-col sm:flex-row gap-4'>
                    <button onClick={() => window.open(_data.fileLink, '_blank')} className='w-full btn'>Adatkezelési Tájékoztató</button>
                    <button onClick={() => history.push(_data.formRef)} className='w-full btn' disabled={!termsAccepted}>Tovább a kérdőívre</button>
                </div>

            </div>

        </div>
    );
}