/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Sticky from 'react-stickynode';

// import DynamicIcon from '@/elements/DynamicIcon.js';
import MultiLevelMenu from '@/elements/MultiLevelMenu.js';

// import globalConfig from '@/config/globalConfig.js'
import { getWebsite } from '@/utils/WebsiteUtils.js';
import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

import globalConfig from '@/config/globalConfig.js'

export default function LeftMenu(props) {
    const [data, setData] = useState(null);
    // const [defaultMenu, setDefaultMenu] = useState(true);

    let location = useLocation();

    const getDefaultMenu = (currentLocation) => {
        let pathName = currentLocation.pathname;
        const pathParts = pathName.substring(1).split('/');
        const basePath = pathParts[0];
        const mainMenu = getValueFromParameterMap(getWebsite().globalContent, 'menu');
        let baseMenu = mainMenu;
        for (let i = 0; i < mainMenu.itemList.length; i++)
            if (mainMenu.itemList[i].path === basePath)
                baseMenu = mainMenu.itemList[i]
        return baseMenu;
    }

    useEffect(() => {
        if (props.data && props.data.value != null) {
            setData(props.data.value)
            // setDefaultMenu(false);
        } else if (location && getValueFromParameterMap(getWebsite().globalContent, 'menu')) {
            setData(getDefaultMenu(location));
            // setDefaultMenu(false);
        }
    }, [props.data, location])

    return (
        <div className={'w-full sm:w-48 md:w-64 bg-tv-purple sm:bg-tv-gray-lighter dark:bg-tv-purple flex flex-col  ' + (props.className || '')} >

            <div className='flex sm:hidden flex-row items-center gap-3 text-tv-yellow font-bold text-xl px-4 pt-4'>
                {/* <DynamicIcon className='opacity-30 text-white' iconName={'FaBars'} /> */}
                <div className='uppercase'>Kapcsolódó oldalaink</div>
            </div>

            {props.sticky ? <Sticky top={globalConfig.etc.headerStickyOffset}><MenuContent data={data} parentData={props.data} /></Sticky> : <MenuContent data={data} parentData={props.data} />}

            {/* <Sticky top={globalConfig.etc.headerStickyOffset}> */}
            {/* <MenuContent data={data} parentData={props.data} /> */}
            {/* <MultiLevelMenu
                    className='w-full px-4 sm:px-6 pt-1 sm:pt-2 pb-6'
                    data={data}
                    firstLevelKey={(props.data && props.data.value) ? 'itemList' : 'childList'}
                    activeClassName='text-tv-yellow sm:text-tv-red-dark'
                    baseClassName='text-xs dark:text-sm tracking-wider font-semibold text-white sm:text-tv-gray-darker dark:text-white'
                    mainClassName=''
                    subClassName=''
                    orbClassName='bg-tv-gray-darkest sm:bg-white dark:bg-tv-gray-darkest'
                    iconClassName='text-white sm:text-tv-gray-darker dark:text-white' /> */}
            {/* </Sticky> */}

        </div>
    );
}

function MenuContent(props) {
    return (
        <MultiLevelMenu
            className='w-full px-4 sm:px-6 pt-1 sm:pt-2 pb-6'
            data={props.data}
            firstLevelKey={(props.parentData && props.parentData.value) ? 'itemList' : 'childList'}
            activeClassName='text-tv-yellow sm:text-tv-red-dark'
            baseClassName='text-xs dark:text-sm tracking-wider font-semibold text-white sm:text-tv-gray-darker dark:text-white'
            mainClassName=''
            subClassName=''
            orbClassName='bg-tv-gray-darkest sm:bg-white dark:bg-tv-gray-darkest'
            iconClassName='text-white sm:text-tv-gray-darker dark:text-white' />
    );
}