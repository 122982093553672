const dictionaryConfig = {
    'close': {
        'hu-HU': 'Bezárás',
        'en-GB': 'Close'
    },
    'important': {
        'hu-HU': 'Fontos',
        'en-GB': 'Important'
    },
    'search': {
        'hu-HU': 'Keresés',
        'en-GB': 'Search'
    },
    'contact': {
        'hu-HU': 'Kapcsolat',
        'en-GB': 'Contact'
    },
    'references': {
        'hu-HU': 'Hivatkozások',
        'en-GB': 'References'
    },
    'current': {
        'hu-HU': 'Aktuális',
        'en-GB': 'Current'
    },
    'accessible-version': {
        'hu-HU': 'Akadálymentes verzió',
        'en-GB': 'Accessible version'
    },
    'to-site-top': {
        'hu-HU': 'Oldal tetejére',
        'en-GB': 'Scroll to top'
    },
    'municipality': {
        'hu-HU': 'Önkormányzat',
        'en-GB': 'Municipality'
    },
    'more': {
        'hu-HU': 'Tovább',
        'en-GB': 'More'
    },
    'read-more': {
        'hu-HU': 'Tovább olvasom',
        'en-GB': 'Read more'
    },
    'download': {
        'hu-HU': 'Letöltés',
        'en-GB': 'Download'
    },
    'more-information': {
        'hu-HU': 'További információk',
        'en-GB': 'More information'
    },
    'search-title': {
        'hu-HU': 'A kereséshez kérjük adjon meg egy, vagy akár több keresési kifejezést.',
        'en-GB': 'Please enter one or more search terms to search.'
    },
    'search-sub-title': {
        'hu-HU': 'Kereshet az oldal tartalma, hírek, események, és dokumentumok között is.',
        'en-GB': 'You can search the site for content, news, events, and documents.'
    },
    'search-placeholder': {
        'hu-HU': 'Írja ide a keresni kívánt kifejezést',
        'en-GB': 'Enter your search term here'
    },
    'search-entered-term': {
        'hu-HU': 'Keresett kifejezés',
        'en-GB': 'Search term'
    },
    'search-count': {
        'hu-HU': 'Találatok száma',
        'en-GB': 'Number of results'
    },
    'search-visible': {
        'hu-HU': 'Megjelenítve',
        'en-GB': 'Displayed'
    },
    'search-more-results': {
        'hu-HU': 'További találatok',
        'en-GB': 'More results'
    },
    'news': {
        'hu-HU': 'Híreink',
        'en-GB': 'Our News'
    },
    'earlier-news': {
        'hu-HU': 'Korábbi híreink',
        'en-GB': 'Our previous news'
    },
    'newsletter': {
        'hu-HU': 'Hírlevél',
        'en-GB': 'Newsletter'
    },
    'newsletter-description': {
        'hu-HU': 'Iratkozzon fel hírlevelünkre, hogy első kézből értesülhessen a kerületet érintő hírekről, információkról.',
        'en-GB': 'Sign up for our newsletter to get first-hand information about the news and information about the district.'
    },
    'newsletter-subscription': {
        'hu-HU': 'Hírlevél feliratkozás',
        'en-GB': 'Newsletter subscription'
    },
    'newsletter-feedback': {
        'hu-HU': 'Köszönjük, sikeresen feliratkozott hírlevelünkre.',
        'en-GB': 'Thank you for successfully subscribing to our newsletter.'
    },
    'name': {
        'hu-HU': 'Név',
        'en-GB': 'Name'
    },
    'email-address': {
        'hu-HU': 'E-mail cím',
        'en-GB': 'E-mail address'
    },
    'cookie-settings': {
        'hu-HU': 'Cookie beállítás',
        'en-GB': 'Cookie settings'
    },
    'cookie-accept-all': {
        'hu-HU': 'Összes elfogadása',
        'en-GB': 'Accept all'
    },
    'cookie-information': {
        'hu-HU': 'Tájékoztató',
        'en-GB': 'Information'
    },
    'cookie-custom': {
        'hu-HU': 'Testreszabás',
        'en-GB': 'Settings'
    },
    'cookie-description': {
        'hu-HU': 'Honlapunk megfelelő működése érdekében Cookie-knak nevezett adatfájlokat (magyarul: sütiket) helyezünk el számítógépén, amelyek javítják a felhasználói élményt és biztosítják az oldal megfelelő működését.',
        'en-GB': 'In order for our website to work properly, like most websites, we sometimes place data files (cookies) on your computer, which improves the user experience and ensures the proper functioning of the site.'
    },
    'login': {
        'hu-HU': 'Bejelentkezés',
        'en-GB': 'Login'
    },
    'logout': {
        'hu-HU': 'Kijelentkezés',
        'en-GB': 'Logout'
    },
    'welcome-user': {
        'hu-HU': 'Üdvözöljük',
        'en-GB': 'Welcome'
    },
    'registration': {
        'hu-HU': 'Regisztráció',
        'en-GB': 'Registration'
    },
    'user-name': {
        'hu-HU': 'Felhasználónév',
        'en-GB': 'Username'
    },
    'password': {
        'hu-HU': 'Jelszó',
        'en-GB': 'Password'
    },
    'password-again': {
        'hu-HU': 'Jelszó ismét',
        'en-GB': 'Password again'
    },
    'full-name': {
        'hu-HU': 'Teljes név',
        'en-GB': 'Full name'
    },
    'phone': {
        'hu-HU': 'Telefonszám',
        'en-GB': 'Phone number'
    },
    'address': {
        'hu-HU': 'Lakcím',
        'en-GB': 'Address'
    },
}

export default dictionaryConfig;