import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Sticky from 'react-stickynode';

import MenuBlock from '@/components/topbar/MenuBlock.js'
import InfoBlock from '@/components/topbar/InfoBlock.js'

import DynamicIcon from '@/elements/DynamicIcon.js'

import globalConfig from '@/config/globalConfig.js'

const sidebarWidgetOpacity = 0.70;
const sidebarWidgetHorizontalMovement = 30;
const typeChangeScaleDeviation = 0.05;
const transition = { ease: 'linear', duration: .15 };

export default function TopBar(props) {
    const [sticky, setSticky] = useState(false);

    const conatiner = useRef(), leftSidebar = useRef(), rightSidebar = useRef();

    useEffect(() => {
        if (leftSidebar.current && rightSidebar.current && conatiner.current) {
            let resizeObserver = new ResizeObserver(() => {
                if (leftSidebar.current && rightSidebar.current && conatiner.current) {
                    leftSidebar.current.style.minWidth = 'initial';
                    rightSidebar.current.style.minWidth = 'initial';
                    if (rightSidebar.current.offsetWidth > leftSidebar.current.offsetWidth)
                        leftSidebar.current.style.minWidth = rightSidebar.current.offsetWidth + 'px';
                    else if (leftSidebar.current.offsetWidth > rightSidebar.current.offsetWidth)
                        rightSidebar.current.style.minWidth = leftSidebar.current.offsetWidth + 'px';
                }
            });
            resizeObserver.observe(conatiner.current);
        }
    }, [leftSidebar, rightSidebar, conatiner])

    return (
        <Sticky className={props.className || ''} innerActiveClass='shadow' onStateChange={s => { setSticky(Sticky.STATUS_FIXED === s.status) }}>

            <div className={'w-full h-6 flex justify-center transition bg-tv-purple dark:bg-tv-yellow ' + (props.mainPage ? 'sm:bg-tv-gray' : 'sm:bg-tv-red-dark')} ref={conatiner}>

                <div className='hidden lg:flex flex-1 gap-1 overflow-hidden' ref={leftSidebar}>

                    <motion.div
                        transition={transition}
                        initial={sticky ? 'visible' : 'hidden'}
                        animate={sticky ? 'visible' : 'hidden'}
                        variants={{ 'visible': { opacity: sidebarWidgetOpacity, x: 0 }, 'hidden': { opacity: 0, x: -sidebarWidgetHorizontalMovement } }}>
                        <Link to='/' onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }}>
                            <img className='h-full py-1.5 px-2 block dark:hidden' alt='logo' src={props.mainPage ? '/assets/images/logo/text-fill-black.svg' : '/assets/images/logo/text-fill-white.svg'} />
                            <img className='h-full py-1.5 px-2 hidden dark:block' alt='logo' src='/assets/images/logo/text-fill-black.svg' />
                        </Link>
                    </motion.div>

                </div>

                <div className='relative max-w-tv-boxed flex-tv-boxed-content'>

                    <InfoBlock className='block sm:hidden' />

                    <div className='hidden sm:block'>
                        <motion.div
                            className='absolute top-0 left-0 w-full h-full flex flex-row justify-between'
                            transition={transition}
                            initial={props.mainPage ? 'visible' : 'hidden'}
                            animate={props.mainPage ? 'visible' : 'hidden'}
                            variants={{ 'visible': { opacity: 1, scale: 1, display: 'flex' }, 'hidden': { opacity: 0, scale: (1 + typeChangeScaleDeviation), transitionEnd: { display: 'none' } } }}>

                            <InfoBlock className='flex-1'/>

                            <div className='overflow-hidden h-full hidden lg:flex'>
                                <motion.div
                                    transition={transition}
                                    initial={sticky ? 'hidden' : 'visible'}
                                    animate={sticky ? 'hidden' : 'visible'}
                                    variants={{ 'visible': { opacity: 1, scale: 1 }, 'hidden': { opacity: 0, scale: 0.70 } }}>

                                    <SocialList className='px-2 md:px-0' mainPage={props.mainPage} />

                                </motion.div>
                            </div>

                            <SocialList className='px-2 md:px-0 hidden sm:flex lg:hidden' mainPage={props.mainPage} />

                        </motion.div>
                    </div>

                    <div className='hidden sm:block'>
                        <motion.div
                            className='absolute top-0 left-0 w-full h-full'
                            transition={transition}
                            initial={props.mainPage ? 'hidden' : 'visible'}
                            animate={props.mainPage ? 'hidden' : 'visible'}
                            variants={{ 'visible': { opacity: 1, scale: 1, display: 'block' }, 'hidden': { opacity: 0, scale: (1 - typeChangeScaleDeviation), transitionEnd: { display: 'none' } } }}>

                            <MenuBlock />

                        </motion.div>
                    </div>

                </div>

                <div className='hidden lg:flex justify-end flex-1 overflow-hidden' ref={rightSidebar}>

                    <motion.div
                        transition={transition}
                        initial={sticky ? 'visible' : 'hidden'}
                        animate={sticky ? 'visible' : 'hidden'}
                        variants={{ 'visible': { opacity: 1, x: 0 }, 'hidden': { opacity: 0, x: sidebarWidgetHorizontalMovement } }}>

                        <SocialList className='px-2' mainPage={props.mainPage} />

                    </motion.div>

                </div>

            </div>

        </Sticky>
    );
}

function SocialList(props) {
    return (globalConfig.social ? <div className={'flex flex-row h-full ' + (props.className || '')}>
        {globalConfig.social.map((item) => (
            <SocialButton className={props.mainPage ? 'text-tv-gray-darker' : 'text-white dark:text-tv-gray-darker'} mainPage={props.mainPage} key={item.key} data={item} />
        ))}
    </div> : null);
}

function SocialButton(props) {
    const [hover, setHover] = useState(false);

    return (
        <a
            className={'text-sm h-full flex items-center justify-center px-1 transition ' + (props.className || '')}
            style={{
                opacity: props.mainPage ? 1 : (hover ? 1 : sidebarWidgetOpacity),
                backgroundColor: hover ? (props.mainPage ? props.data.color : 'rgba(255,255,255,0.3)') : 'initial',
                color: hover ? 'rgba(255,255,255,0.9)' : ''
            }}
            onMouseEnter={() => { setHover(true) }}
            onMouseLeave={() => { setHover(false) }}
            href={props.data.url}
            target='_blank'
            rel='noreferrer'
            title={props.data.label}>
            <DynamicIcon iconName={props.data.iconName} />
        </a>
    );
}