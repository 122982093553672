import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

import { getSearchUrl } from '@/utils/GenericUtils';
import { getTranslation } from '@/utils/DictionaryUtils.js';

export default function SearchInput(props) {
    const [term, setTerm] = useState('');

    const history = useHistory();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter')
            search();
    }

    const search = () => {
        if (term !== '')
            history.push(getSearchUrl(term));
    }

    if (props.fill) {
        return (
            <motion.div
                className={'flex items-center justify-center h-full ' + (props.className || '')}
                initial={props.open ? 'visible' : 'hidden'}
                animate={props.open ? 'visible' : 'hidden'}
                variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0, transitionEnd: { display: 'none' } } }}>

                <motion.div
                    className={'mx-4 sm:mx-0 px-1 sm:px-2 w-full h-full flex flex-col items-center justify-center bg-tv-gray-lighter dark:bg-tv-yellow rounded-full sm:ring-4 ring-tv-gray-lightest dark:ring-tv-yellow-light'}
                    initial={props.open ? 'visible' : 'hidden'}
                    animate={props.open ? 'visible' : 'hidden'}
                    variants={{ visible: { scale: 1 }, hidden: { scale: 0.5 } }}>

                    <div className='w-full h-full py-1 sm:py-2 flex flex-row items-center text-tv-gray-dark dark:text-white text-sm sm:text-lg'>

                        <button className='w-8 h-full bg-tv-red rounded-full shadow flex items-center justify-center' onClick={() => search()}>
                            {/* <img className='w-full h-full block dark:hidden' alt='search' src='/assets/images/icon/search-black.svg' /> */}
                            <img className='w-1/2 h-1/2' alt='search' src='/assets/images/icon/search-white.svg' />
                        </button>

                        <input className='flex-1 bg-transparent outline-none h-full placeholder-tv-gray-darkest text-center'
                            type='text'
                            name='search'
                            placeholder={getTranslation('search-placeholder') + ' ...'}
                            value={term}
                            onChange={e => setTerm(e.target.value)}
                            onKeyDown={handleKeyDown} />

                        <button className='w-8 h-full bg-tv-purple rounded-full shadow flex items-center justify-center' onClick={() => props.onClose()}>
                            {/* <img className='w-full h-full block dark:hidden' alt='search' src='/assets/images/icon/search-black.svg' /> */}
                            <img className='w-1/3 h-1/3' alt='close' src='/assets/images/icon/close-white.svg' />
                        </button>

                    </div>

                </motion.div>

            </motion.div>
        );
    } else {
        return (
            <div className={'bg-tv-gray-lighter h-8 rounded-full p-1 flex-row ' + (props.className || '')}>

                <div className='rounded-full h-full w-6'>
                    <img className='w-full h-full p-1.5' alt='logo' src='/assets/images/icon/search-black.svg' />
                </div>

                <input className='bg-transparent rounded-r-full  p-1 h-full outline-none w-8 flex-1 text-xs'
                    type='text' name='search'
                    placeholder={getTranslation('search') + ' ...'}
                    onChange={e => setTerm(e.target.value)}
                    onKeyDown={handleKeyDown} />

            </div>
        );
    }
}